.mainBoxWrapper {
  width: 100%;
  height: 100%;
  /* display: flex !important; */
  /* flex-direction: column; */
  align-items: center !important;
  justify-content: center;
  padding-top: 20px;
}

.mainBoxWrapperResult {
  width: 100%;
  height: 100vh;
  /* display: flex !important;
  flex-direction: column; */
  align-items: center !important;
  justify-content: center;
  padding-top: 10px;
}

.mainBox {
  /* max-width: 980px !important;
  width: 980px !important; */
  /* overflow-y: scroll; */
  height: 78vh !important;
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 30px 30px;
  padding-right: 5px;
  padding-left: 5px;
  margin: auto;
  position: relative !important;
}

.mainBoxResult {
  /* max-width: 980px !important;
  width: 980px !important; */
  /* overflow-y: scroll; */
  /* height: 78vh !important; */
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 5px 5px;
  padding-right: 5px;
  padding-left: 5px;
  margin: auto;
  position: relative !important;
}

.mainBoxAfterResult {
  overflow-y: hidden;
  overflow-x: hidden;
  /* height: 78vh !important; */
  padding: 0px 0px 100px 0px !important;
  scroll-behavior: smooth;
  width: 100%;
}

.mainBoxAfterResult::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.mainBoxAfterResult::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(228, 228, 228);
  border-radius: 10px;
  height: 80px;
}

/* Handle */
.mainBoxAfterResult::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 10px;
  border-right: solid 5px transparent;
}

/* Handle on hover */
.mainBoxAfterResult::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.mainBoxAfterResult::-webkit-scrollbar-button {
  height: 22px;
}

/* mainBoxAfter */
/* mainBoxAfter */
/* mainBoxAfter */
.mainBoxAfter {
  overflow-y: scroll;
  height: 78vh !important;
  padding: 0px 0px 50px 0px !important;
  scroll-behavior: smooth;
}

/* scroll bar */
.mainBoxAfter::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.mainBoxAfter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(228, 228, 228);
  border-radius: 10px;
  height: 80px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Handle */
.mainBoxAfter::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 10px;
  border-right: solid 5px transparent;
}

/* Handle on hover */
.mainBoxAfter::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.mainBoxAfter::-webkit-scrollbar-button {
  height: 22px;
}

/* scroll bar */
.testSection {
  margin-top: 100px;
  text-align: center;
}

.whiteLine {
  border-bottom: 1px solid #ccc;
  max-width: 100%;
  margin: 30px auto;
}

.questionHeading {
  color: #fff !important;
  font-family: var(--mont);
  text-align: center;
  font-size: 1.7rem;
  max-width: 570px;
  margin: 0 auto;
}

.questionMainDiv {
  max-width: 60%;
  margin: 0 auto;
}

.questionMainDivError {
  border: 1px #EF0000 solid;
}

.cautionErrorImg {
  height: 27px;
  margin-top: 45px;
  margin-right: -45px;
  float: right;
  align-self: flex-end;
}

.errorHeading {
  background-color: #EF0000;
  color: #fff !important;
  font-family: var(--mont);
  text-align: center;
  font-size: 1.7rem;
  max-width: 60%;
  margin: 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}

.checkboxWrapper {
  margin-left: 2%;
  margin-right: 2%;
}

.checkLabel {
  color: #fff !important;
  font-family: var(--mont);
  margin-top: -5px;
  font-size: 1.2rem;
}

.disabled {
  opacity: 0.2;
}

.reportBtn {
  color: #000 !important;
  background-color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 30px !important;
  border-radius: 15px !important;
  padding: 10px 50px !important;
}

.achorDisable {
  pointer-events: none;
  opacity: 0.4;
}

@media only screen and (max-width: 995px) {
  .mainBox {
    width: 100% !important;
  }

  .mainBoxResult {
    width: 100% !important;
  }

  .errorHeading {
    max-width: 60%;
  }

  .checkLabel {
    font-size: 1rem;
  }

  .questionHeading {
    font-size: 1.1rem;
  }

  .checkboxWrapper {
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media only screen and (max-width: 768px) {
  .reportBtn {
    font-size: 20px !important;
    border-radius: 10px !important;
    padding: 5px 30px !important;
  }

  .mainBoxAfter::-webkit-scrollbar {
    width: 0px;
  }

  .mainBoxAfterResult::-webkit-scrollbar {
    width: 0px;
  }

  .mainBox {
    padding-right: 0px;
    padding-left: 0px;
  }

  .mainBoxResult {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 684px) {
  .questionMainDiv {
    max-width: 70%;
  }

  .errorHeading {
    max-width: 70%;
    font-size: 1rem;
  }

  .questionHeading {
    max-width: 90%;
  }

  .optionsChecks {
    max-width: 90%;
  }

  .checkLabel {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 538px) {
  .questionMainDiv {
    max-width: 80%;
  }

  .errorHeading {
    max-width: 80%;
  }
}

@media only screen and (max-width: 445px) {
  .questionMainDiv {
    max-width: 90%;
  }

  .errorHeading {
    max-width: 90%;
  }
}

@media only screen and (max-width: 375px) {
  .questionMainDiv {
    max-width: 95%;
  }

  .errorHeading {
    max-width: 95%;
  }
}


/* @media only screen and (max-width: 767px) {
  .customOptions {
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 768px) {
  .customOptions {
    justify-content: space-between !important;
  }
} */
