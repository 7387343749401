.mainBoxWrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}

.mainBox {
  max-width: 980px !important;
  width: 980px !important;
  /* overflow-y: scroll; */
  height: 78vh !important;
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 30px 30px;
  padding-right: 5px;
  padding-left: 5px;
  /* margin: auto; */

  position: relative !important;
}

.mainBoxAfter {
  overflow-y: scroll;
  height: 78vh !important;
  padding: 0px 0px 50px 0px !important;
}

/* scroll bar */
.mainBoxAfter::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.mainBoxAfter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(228, 228, 228);
  border-radius: 10px;
  height: 80px;
}

/* Handle */
.mainBoxAfter::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 10px;
  border-right: solid 5px transparent;
}

/* Handle on hover */
.mainBoxAfter::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.mainBoxAfter::-webkit-scrollbar-button {
  height: 22px;
}

.modalEmailSentTitle {
  font-size: 45px;
  font-weight: normal;
  letter-spacing: 2px;
  text-align: center;
  margin: 24px 0px 12px 0;
}

.modalEmailSentMessage {
  font-size: 21px;
  font-weight: normal;
  letter-spacing: 2px;
  text-align: center;
  margin: 14px 0px 52px 0;
}

/* scroll bar */
@media only screen and (max-width: 995px) {
  .mainBox {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .mainBoxAfter::-webkit-scrollbar {
    width: 0px;
  }

  .mainBox {
    padding-right: 0px;
    padding-left: 0px;
  }
}

/* ************ */
/* login page */
.loginMainHead {
  font-size: 62px;
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  margin-top: 50px;
  letter-spacing: 7px;
}

.loginMainPara {
  font-size: 15px;
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  max-width: 480px !important;
  margin: -3px auto;
  text-align: left;
}

.loginSignupRow {
  width: 410px !important;
}

.rowParent {
  display: grid;
  justify-content: center !important;
}

.loginSignupLabelLine1 {
  height: 1px;
  background-color: #fff;
  /* transform: rotate(10.3deg); */
}

.youAreInBlockParent {
  display: grid;
  justify-content: center !important;
}

.youAreInBlock {
  width: 620px !important;
}

.btnSignup {
  /* background-color: rgb(255, 64, 64) !important; */
  background-color: rgb(0, 87, 225) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 22px !important;
  width: 100%;
  max-width: 410px !important;
  margin: 0 auto;
}

.btnSignup:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnStartTest {
  /* background-color: rgb(255, 64, 64) !important; */
  background-color: rgb(0, 87, 225) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 22px !important;
  width: 100%;
  max-width: 410px !important;
  margin: 0 auto;
  letter-spacing: 0.15em;
}

.btnStartTest:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnSignupWalletOnly {
  background-color: rgb(30, 117, 255) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 20px !important;
  margin: 0 auto;
  width: 100%;
  max-width: 410px !important;
  border-radius: 7px;
  text-align: center;
}

.btnSignupWalletOnly:hover {
  background-color: rgb(200, 224, 254) !important;
  color: rgb(30, 117, 255) !important;
}

.loginTermWrapper {
  max-width: 410px !important;
  margin: 0 auto;
}

.loginTermWrapperWalletOnly {
  max-width: 410px !important;
  margin: 0 auto;
}

.loginTerm {
  float: right;
  font-size: 12px !important;
  color: rgb(199, 199, 199) !important;
  cursor: pointer;
}

.btnFacebook {
  display: block !important;
  background-color: rgb(30, 117, 255) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 28px !important;
  padding: 0 60px !important;
  margin: 20px auto;
}

.btnFacebook:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnGoogle {
  display: block !important;
  background-color: rgb(237, 88, 41) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 28px !important;
  padding: 0 78px !important;
  margin: 20px auto;
}

.btnGoogle:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnLinked {
  display: block !important;
  background-color: rgb(0, 58, 150) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 28px !important;
  padding: 0 70px !important;
  margin: 20px auto;
}

.btnLinked:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnMata {
  display: block !important;
  background-color: rgb(230, 111, 0) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 23px !important;
  margin: 20px auto;
  width: 100%;
  max-width: 410px !important;
  margin: 0 auto;
  letter-spacing: 4px;
}

.btnMata:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnWallet {
  display: block !important;
  background-color: rgb(0, 87, 225) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 28px !important;
  padding: 0 30px !important;
  margin: 20px auto;
}

.btnWallet:hover {
  background-color: rgb(200, 224, 254) !important;
}

.btnCoin {
  display: block !important;
  background-color: rgb(0, 87, 225) !important;
  color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 28px !important;
  padding: 0 63px !important;
  margin: 20px auto;
}

.btnCoin:hover {
  background-color: rgb(200, 224, 254) !important;
}

.loginUsingHead {
  font-family: var(--mont);
  color: #fff;
  font-size: 27px;
  font-weight: bold;
}

.loginWhiteLine {
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 27px;
  font-family: var(--mont);
  margin-bottom: 50px;
}

.loginWhiteLine:before,
.loginWhiteLine:after {
  background-color: #fff;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 30%;
}

.loginWhiteLine:before {
  right: 0.5em;
  margin-left: -50%;
}

.loginWhiteLine:after {
  left: 0.5em;
  margin-right: -50%;
}

.loginInput {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  border: none !important;
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: var(--mont);
  border-radius: 5px;
}

.loginInput:focus {
  box-shadow: none !important;
}

.loginInputBB span {
  background-color: rgb(153, 150, 152) !important;
  border-color: rgb(153, 150, 152) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-family: var(--mont);
}


.loginInputWalletOnly {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  border: none !important;
  font-size: 17px !important;
  font-weight: bold !important;
  font-family: var(--mont);
  border-radius: 5px;
}

.loginInputWalletOnly:focus {
  box-shadow: none !important;
}

.loginInputWalletOnlyBB span {
  background-color: rgb(153, 150, 152) !important;
  border-color: rgb(153, 150, 152) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-family: var(--mont);
}

@media only screen and (max-width: 768px) {
  .loginMainHead {
    font-size: 35px;
  }

  .btnSignup {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 50px !important;
  }

  .btnFacebook {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 60px !important;
    margin: 20px auto;
  }

  .btnGoogle {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 70px !important;
    margin: 20px auto;
  }

  .btnLinked {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 64px !important;
    margin: 20px auto;
  }

  .btnMata {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 55px !important;
  }

  .btnWallet {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 36px !important;
  }

  .btnCoin {
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 10px 60px !important;
  }
}

.profileBtnGroup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 15px;
  padding-top: 10px;
}

.signupbtn {
  background: transparent;
  color: rgb(30, 117, 255) !important;
  font-weight: bold;
  font-size: 18px;
  border: none;
  padding: 0px;
  text-decoration: underline;
}

/* login page end */
/*****************/