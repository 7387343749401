.mainBoxWrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}
.mainBox {
  max-width: 980px !important;
  width: 980px !important;
  /* overflow-y: scroll; */
  /* height: 78vh !important; */
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 30px 30px;
  padding-right: 5px;
  padding-left: 5px;
  /* margin: auto; */

  position: relative !important;
}
.mainBoxAfter {
  /* overflow-y: scroll; */
  /* height: 78vh !important; */
  padding: 0px 0px 50px 0px !important;
}
/* scroll bar */
.mainBoxAfter::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.mainBoxAfter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(228, 228, 228);
  border-radius: 10px;
  height: 80px;
}

/* Handle */
.mainBoxAfter::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 10px;
  border-right: solid 5px transparent;
}

/* Handle on hover */
.mainBoxAfter::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.mainBoxAfter::-webkit-scrollbar-button {
  height: 22px;
}
/* scroll bar */
@media only screen and (max-width: 995px) {
  .mainBox {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .mainBoxAfter::-webkit-scrollbar {
    width: 0px;
  }
  .mainBox {
    padding-right: 0px;
    padding-left: 0px;
  }
}

/* *************************** */
/* community page */
.communityMain {
  color: #fff;
  text-align: center;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 52px;
  margin-top: 10px;
}
.communityMainPara {
  color: #fff;
  font-family: var(--mont);
  font-size: 20px;
  text-align: center;
  max-width: 645px;
  margin: 30px auto !important;
}
.communitySubHead {
  font-size: 34px;
  font-weight: bold;
  font-family: var(--mont);
  color: #fff;
}
.communitySubPara {
  color: #fff;
  font-size: 22px;
  font-family: var(--mont);
  font-weight: bold;
  margin-bottom: 0px !important;
  margin-left: 30px;
}
.communitySubPara a {
  color: #fff;
  text-decoration: underline;
}
.communitySubPara a:hover {
  color: #fff;
  text-decoration: underline;
}
.communitySubDis {
  color: #fff;
  font-size: 14px;
  font-family: var(--mont);
  font-weight: bold;
  margin-left: 30px;
}
@media only screen and (max-width: 768px) {
  .communityMain {
    font-size: 35px;
  }
  .communitySubHead {
    font-size: 25px;
    margin-top: 50px;
  }
  .checkboxWrapper {
    margin-left: 0%;
    margin-right: 0%;
  }
  .checkLabel {
    font-size: 0.8rem;
  }
}
/* community page end */
/* *************************** */
