/* PERCENTAGE START */



/* PERCENTAGE END */
.navigation-style {
  background-color: rgba(0, 0, 0, 0.82);
  border-radius: 30px 30px 0px 0px;
  color: #fff !important;
  /* margin-bottom: 50px !important; */
  /* position: fixed !important; */
  /* top: 84px; */
  display: flex !important;

  /* width: 980px !important;
  max-width: 980px !important; */
  z-index: 100;
  transition: 0.3s;
}

.navigation-style-result {
  background-color: rgba(0, 0, 0, 0.82);
  border-radius: 30px 30px 0px 0px;
  color: #fff !important;
  /* margin-bottom: 50px !important; */
  /* position: fixed !important; */
  /* top: 84px; */
  display: block !important;

  /* width: 980px !important;
  max-width: 980px !important; */
  z-index: 100;
  transition: 0.3s;
}

/* .navbar{
  display: inline-block;
  align-self: center;
  align-items: center;
  text-align: center;
} */
.navbar-stiky {
  transition: 0.3s;
  top: 0px !important;
  border-radius: 0px !important;
}

.navbar-brand {
  font-family: var(--mont) !important;
  font-size: 1.8rem !important;
  letter-spacing: 1.5px;
  margin-left: 20px;
}

.navbar-brand-disabled {
  color: #9b9b9b !important;
}

.navbar-brand-enabled {
  color: #fff !important;
}

.resultLinkParent {
  display: contents;
  float: right;
  text-align: right;
}
.padding-left-20{
  padding-right: 9px;
}

.resultLinkBtn {
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  float: right;
  text-align: right;
  cursor: pointer;
  margin: 1.75% 1.5%;
}

.resultLinkBtn:hover {
  font-weight: bold;
  color: #fff;
}

.resultLink {
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  float: right;
  text-align: right;
  cursor: pointer;
  margin: 1% 2%;
}

.customHeaderNav .btn-secondary {
  background: black !important;
  border-radius: 0px !important;
  background-color: black !important;
  border: none;
}

.resultLink:hover {
  font-weight: bold;
  color: #fff;
}

.resultLinkInactive {
  font-weight: normal;
  color: #fff;
}

.resultLinkActive {
  font-weight: bold;
  color: #fff;
}

.userButtonGrey {
  font-size: 16px;
  font-weight: bolder;
  color: #000;
  background-color: #E5EBFA;
  border: 0;
  height: 35px;
  padding-top: 5px;
  width: 150px;
  border-radius: 7px;
  float: right;
  text-align: justify;

  margin-left: 2%;
  cursor: pointer;
  margin-top: 1%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.userButtonGrey:hover {
  color: #fff;
  background-color: #0057E1;
}

.userButtonGreyClickedLogout {
  border-radius: 0px !important;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom: 1px solid #d3d3d3;
}

.userButtonGreyLogout {
  position: absolute;
  right: 16px;
  top: 42px;
  font-size: 16px;
  font-weight: bolder;
  color: #000;
  background-color: #E5EBFA;
  border: 0;
  height: 35px;
  padding-top: 5px;
  width: 150px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  float: right;
  text-align: center;

  margin-left: 2%;
  cursor: pointer;
  margin-top: 1%;
  overflow: hidden;
  text-overflow: ellipsis;

}

.userButtonGreyLogout:hover {
  color: #fff;
  background-color: #0057E1;
}

.nav-link {
  font-size: 1.4rem !important;
  color: #fff !important;
  padding-top: 20px !important;
  padding-right: 20px !important;
}

.headerFirstDiv {
  width: 30% !important;
}

.navbar-nav {
  flex-direction: row !important;
  align-items: center;
}

@media only screen and (max-width: 995px) {
  .navigation-style {
    display: flex !important;
    width: 100% !important;
  }

  .navigation-style-result {
    /* display: inline-block !important; */
    width: 100% !important;
  }

  .navbar-brand {
    letter-spacing: 1.5px;
  }
}

@media only screen and (max-width: 886px) {
  .headerFirstDiv {
    width: 20% !important;
  }
}

@media only screen and (max-width: 786px) {
  .navbar-brand {
    margin-left: 0%;
    letter-spacing: 0px;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
  }

  .nav-link {
    font-size: 1rem !important;
    color: #fff !important;
    padding-top: 15px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .headerFirstDiv {
    width: 35% !important;
  }
}

@media only screen and (max-width: 695px) {
  .headerFirstDiv {
    display: none;
  }

  .completionWord {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 995px) {
  .navbar-nav {
    padding-top: 5px;
  }

  .resultLinkParentNone {
    display: none;
  }

  .navigation-style-result {
    text-align: center;
  }

}

@media only screen and (max-width: 486px) {
  .navbar-nav {
    padding-top: 10px;
  }

  .headerFirstDiv {
    width: 20% !important;
  }

  .completionWord {
    font-size: 1rem !important;
  }

  .completionWordPercentage {
    font-size: 1rem !important;
    margin-top: 10px;

  }
}

/* @media only screen and (max-width: 420px) {
  .navbar-nav {
    flex-direction: column !important;
  }
} */

@media only screen and (max-width: 400px) {
  .headerFirstDiv {
    width: 10% !important;
  }
}

@media only screen and (max-width: 360px) {
  .headerFirstDiv {
    width: 0% !important;
  }
}
