html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "saira";
}
body {
  font-family: 'Saira', sans-serif;
}

.modal-open #root {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.modal-content {
  filter: blur(0px) !important;
}
