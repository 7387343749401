@media only screen and (max-width: 767px) {
  .customOptions {
    justify-content: space-between !important;
  }
  .mobileCustomOptions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between !important;
  }
  .desktopLabel {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .customOptions {
    justify-content: space-between !important;
  }
  .mobileCustomOptions {
    display: none;
  }
}

