.mainBg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}
.mainBgFullHeight {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.mainBgFullHeightPDF {
  background-position: center center;
  background-size: contain;
  background-repeat: repeat;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
