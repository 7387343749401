

@media only screen and (min-width: 1200px) {
  .profileContainer {
    padding: 5rem 3rem;
    color: white;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1199px) {
  .profileContainer {
    padding: 5rem 3rem;
    color: white;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
  }
}

.edit-profile-modal .btn-close {
  display: none;
}

.profileAvatar {
  width: 350px;
  height: 350px;
  background-color: gray;
  border-radius: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.imgAvatar {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.userName {
  font-size: 2rem;
  letter-spacing: 5px;
  margin-bottom: 0px;
}


@media only screen and (min-width: 1200px) {
  .profileDetails {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1199px) {
  .profileDetails {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.matchSettings {
  margin-top: 1rem;
}

.matchTitle {
  font-size: 32px;
  letter-spacing: 5px;
  margin-bottom: 0px;
}

.profileTitle {
  font-size: 2.5rem;
  margin-bottom: 5px;
}

.profileData p {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.emailResultsBtn {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  border-radius: 7px;
  text-align: center;
  max-width: 150px !important;
  width: 100% !important;
}

@media only screen and (max-width: 582px) {
  .btnMata {
    background-color: rgb(230, 111, 0) !important;
    color: #fff !important;
    font-family: var(--mont) !important;
    font-weight: bold !important;
    font-size: 22px !important;
    margin: 20px auto;
    max-width: 350px !important;
    margin: 0 auto;
    padding: 3px 40px !important;
  }
}

@media only screen and (min-width: 583px) {
  .btnMata {
    background-color: rgb(230, 111, 0) !important;
    color: #fff !important;
    font-family: var(--mont) !important;
    font-weight: bold !important;
    font-size: 22px !important;
    margin: 20px auto;
    max-width: 350px !important;
    margin: 0 auto;
    padding: 3px 40px !important;
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 450px) {
  .profileAvatar {
    width: 275px;
    height: 275px;
  }
}


.btnMata:hover {
  background-color: rgb(200, 224, 254) !important;
}

.profileData span {
  letter-spacing: 2px !important;
  font-weight: normal !important;
}

.profileData p {
  margin-bottom: 15px !important;
}

.profileBtnGroup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 15px;
  column-gap: 10px;
  padding-top: 10px;
}

.profileConnectedBtn {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  border-radius: 7px;
  text-align: center;
  width: 45% !important;
}