/* results page */
@media print {
  #ResultPage {
    line-height: 2;
    /* margin-top: 1rem;
    display: block;
    page-break-before: auto; */
  }

  .resultCardMainImageWrapper {
    padding-top: 10px;
  }

  .DownloadLinksMain {
    display: none;
  }

  .DownloadLinksPrint {
    display: flex;
  }
}

#mycanvasSVGNew {
  image-rendering: pixelated;
  image-rendering: optimizespeed;
}

.resultDetailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding-top: 5px;
  padding-bottom: 30px;
}

.resultDetailsGrid > p {
  margin-bottom: 0px;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.footerFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.resultFooterButton {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1.2px;
  color: #fff;
  background-color: #000;
  border: 0;
  height: 60px;
  width: 100%;
  text-align: center;
  border-top: 1px #fff solid;
}

/* .resultFooterButton:hover{
  background-color: #0057E1;
} */
.resultFooterButtonActive {
  font-weight: bolder;
}

.footerBorderLeftWhite {
  border-left: 2px #fff solid;
}

.footerBorderRightWhite {
  border-right: 2px #fff solid;
}

@media only screen and (min-width: 995px) {
  .footer {
    display: none;
  }
}

.personalityImageParent {
  position: relative;
}

.imagePMain {
  position: relative;
  top: 0;
  left: 0;
}

.imageP1 {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 150px;
  left: 160px;
}

.open-button {
  height: 56px;
  width: 56px;
  background-color: rgb(255, 64, 64);
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  border-radius: 50%;
}

/* The popup chat - hidden by default */
.chat-popup {
  width: 360px;
  display: block;
  position: fixed;
  bottom: 0;
  right: 28px;
  bottom: 93px;
  /* border: 3px solid #f1f1f1; */
  border-radius: 8px;
  background-color: #fff;
  z-index: 1009;
}

.messageArea {
  height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.headerChat {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #FF4040;
  color: #fff;
}

.chatHeading {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
}

.chatIsOperatorActive {
  color: rgb(239, 242, 247);
  font-size: 14px;
  line-height: 24px;
}

.userIcon {
  background-size: cover;
  background-position: center center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.chatMessageUser1 {
  background-color: rgb(239, 242, 247);
  margin-left: 5px;
  border-radius: 16px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
}

.chatMessageUser2 {
  background-color: rgb(255, 64, 64);
  color: rgb(249, 250, 252);
  margin-left: 5px;
  border-radius: 16px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
}

.chatDate {
  color: rgb(132, 146, 166);
  font-size: 14px;
  text-align: center;
}

.chatTime {
  color: rgb(132, 146, 166);
  font-size: 12px;
  text-align: right;
  margin-top: -10px
}

.chatMessageInput {
  border: 0;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
}

.sendButton {
  color: rgb(194, 231, 253);
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  cursor: pointer;
  text-transform: uppercase;
  border: 0;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}


.closeModalBtn {
  cursor: "pointer";
  height: 40 !important;
  width: 40 !important;
  font-weight: "lighter";
  color: #fff;
  position: 'absolute';
  top: '-10%';
  right: '-10%';
}

.DownloadLinksMain {
  display: flex !important;
}

/* .DownloadLinksPrint{
  display: none !important;
} */

.DownloadLinkColumn1 {
  text-align: right;
}

.DownloadLinkColumn2 {
  text-align: left;
}

.personalityCardImageFontSize {
  font-size: 0.85vw !important;
  font-weight: normal;
}

.rgbValues1 {
  stop-color: rgb(255, 255, 0);
  stop-opacity: 1;
}

.rgbValues2 {
  stop-color: rgb(255, 0, 0);
  stop-opacity: 1;
}

.resultCardMain1 {
  margin-right: 25px;
  /* min-height: 350px !important;
  width: 240 !important;
  height: 350 !important; */

  width: 100%;
  height: 100%;
  max-width: 100% !important;
}

.resultCardMain1Modal {
  margin-right: 25px;
  min-height: 58.625 !important;
  width: 40.125 !important;
  height: 58.625 !important;
}

.modalMint .modal-content {
  background-color: black !important;
  border-radius: 15px;
  
}

.mintModal .modal-content {
  border: 1px solid white;
  border-radius: 15px;
}

.modalEmail .modal-content {
  background-color: black !important;
  border-radius: 15px;
}

.modalEmailTitle {
  font-style: italic;
  font-size: 58px;
  font-weight: bolder;
  letter-spacing: normal;
  text-align: center;
  margin: 44px 0px 52px 0;
}

.modalEmailDescription {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.4em;
  text-align: center;
  margin-left: 44px;
  margin-right: 44px;
}

.modalEmailText {
  background-color: #373737;
  height: 45px;
  width: 310px;
  border: 0;
  border-radius: 4px;
  float: right;
  font-size: 20px;
  color: #fff;
  padding-left: 10px;
  /* #C7C7C7 */
}

.modalEmailText::placeholder {

  /* line-height: 1.9; */
  color: #C7C7C7;
  /* text-decoration: underline; */
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.modalEmailSignupButton {
  font-size: 24px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  float: left;
  border: 0;
  height: 45px;
  width: 170px;
  border-radius: 7px;
}

.modalEmailOkButton {
  font-size: 24px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  height: 45px;
  width: 170px;
  border-radius: 7px;
}

.modalEmailSignupButton:hover {
  background-color: #C8E0FE;
}
.modalMint .modal-content {
  background-color: rgba(0, 0, 0, 0.63) !important;
  /* background-color: black !important; */
  border-radius: 15px;
  padding-left: 8% !important;
  padding-right: 8% !important;
}

.modalCongrats .modal-content {
  background : rgb(24, 24, 24) url('../../assets/confetti-40.gif') !important;
  border-radius: 15px;
  border: 1px #fff !important;
}
.resultYourResultHeadingCongrats {
  color: #fff;
  font-family: var(--mont);
  font-weight: bolder;
  font-size: 50px;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 8px;
  margin-top: 150px;
}
.resultYourResultTitleCongrats {
  color: #fff;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 4px;
  margin-top: 10px;
}
.modalDowngradeDescriptionCongrats {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1em;
  text-align: center;
  margin-left: 44px;
  margin-right: 44px;
  letter-spacing: 4px;
}

.modalDowngrade .modal-content {
  background-color: black !important;
  border-radius: 15px;
}

.modalDowngradeTitle {
  font-style: normal;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 3px;
  text-align: center;
  margin: 44px 0px 32px 0;
}

.modalDowngradeDescription {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.4em;
  text-align: center;
  margin-left: 44px;
  margin-right: 44px;
}

.modalDowngradeValuesText {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.4em;
}

@media only screen and (max-width: 1199px) {
  .iframeCard {
    border: none;
    border-radius: 3px;
    max-width: 349px;
    width: 100%;
  }

  .iframeCardMint {
    border: none;
    border-radius: 3px;
    text-align: left;
    max-width: 349px;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .iframeCard {
    position: relative;
    border: none;
    border-radius: 3px;
    /* margin-left: 37px; */
    /* max-width: 349px; */
    /* width: 100%; */
    /* transform: scale(1.25); */
  }

  .iframeCardMint {
    border: none;
    border-radius: 3px;
    margin-left: 37px;
    text-align: left;
    max-width: 349px;
    width: 100%;
  }
}


@media only screen and (max-width: 699px) {
  .mintModal svg {
    top: 0.5%;
    right: 2.5%;
  } 
}

@media only screen and (min-width: 700px) {
  .mintModal svg {
    top: 1.5%;
    right: 1%;
  }
}

.mintModalSize {
  max-width: 1240px !important;
  width: 100%;
}

.mintModalTitle {
  left: 0% !important;
}

.modal-backdrop {
  background-color: white !important;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem) !important;
}

/* .iframeCardDiv{
  margin-left: 20px;
} */
/* .modalDowngroundMainColumn1 {
  border-right: 1px solid #818181;
} */

.modalDowngroundMainColumn1:hover {
  border: 2px solid red;
  border-radius: 17px;
}

.modalDowngroundMainColumnActive {
  border: 2px solid red !important;
  border-radius: 17px;
}

.modalDowngroundMainColumn2:hover {
  border: 2px solid red;
  border-radius: 17px;
}

.modalDowngroundMainColumn3 {
  border-right: 1px solid #818181;
}

.modalDowngroundMainColumn3:hover {
  border: 2px solid red;
  border-radius: 17px;
}

.modalDowngroundMainColumn4:hover {
  border: 2px solid red;
  border-radius: 17px;
}

.modalDowngroundMainColumn5 {
  border-right: 1px solid #818181;
}

.modalDowngroundMainColumn5:hover {
  border: 2px solid red;
  border-radius: 17px;
}

.modalDowngroundMainColumn6:hover {
  border: 2px solid red;
  border-radius: 17px;
}

.modalDowngradeRarityButton {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #fff;
  font-family: var(--mont) !important;
  background-color: red;
  border: 0;
  width: 100%;
  max-width: 440px !important;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;

}

.modalDowngradeRarityButton:hover {
  background-color: #fff;
  color: red;
}

.testPageHeading {
  margin-top: 65px;
  font-family: var(--mont);
  font-size: 52px;
  letter-spacing: 0.01em;
  color: #fff;
  font-weight: bold;
}

.resultCardMainWrapper {
  margin-top: 100px;
  /* overflow: hidden;
  display: inline-block; */
}

/* .resultCardMainWrapper img {
  
  transform-origin: 14px 16px;
  transition: transform .25s, visibility .25s ease-in-out;
}
.resultCardMainWrapper:hover img {
  
  transform: scale(2.4);
} */
/* ************** */
/* positioning  */
/* ************* */

.resultCardMain2 {
  margin-left: 25px;
}

.resultCard2Main {
  text-align: center;
  margin: 48px;
}

.resultCardMainImageWrapper {
  position: relative;
  align-items: center;
  justify-content: center;

}

/* Double Card Animation START*/

.scene3d {
  perspective: 3000px;
  /* width: 600px; */
  height: 540px;
  margin-left: auto;
  margin-right: auto;
}

.object3d {
  position: absolute;
  /* width: inherit;
  height: inherit; */
  /*top: 20px;*/
  transform-style: preserve-3d;
}

.face3d {
  position: absolute;
  /* left: 165px;
  top: 15px; */
}

#im1 {
  transform: translateZ(1px);
}

#personalityImage {
  /* transform: translateY(0px);  */
  transform: scaleX(-1);
}

.scene3d.begin {
  perspective: 2000px;
}

.scene3d.end {
  perspective: 10px;
}

#obj1 {
  animation: theRotate 4s linear infinite;
}

@keyframes theRotate {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

/* Double Card Flip on Click Animation */

.card__inner {
  /* border: 2px solid white; */
  padding: 15px 15px 15px 15px;
  width: 321px;
  height: 469px;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card__inner.is-flipped {
  transform: rotateY(180deg);
}


.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.card__face--front {
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.card__face--back {
  transform: rotateY(180deg) scaleX(1);
}

/* Double Card Flip on Click Animation */

/* Double Card Flip Temp Result on Click Animation */

.virtualCardCenter {
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .card__inner_temp_result {
    width: 321px;
    height: 469px;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
  }

  .virtualCard {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .card__inner_temp_result {
    width: 321px;
    height: 469px;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
  }

  .virtualCard {
    display: flex;
    justify-content: center;
  }
}


.card__inner_temp_result.is-flipped {
  transform: rotateY(180deg);
}


.card__face_temp_result {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.card__face_temp_result--front {
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.card__face_temp_result--back {
  transform: rotateY(180deg) scaleX(1);
}

/* Double Card Flip Temp Result on Click Animation */

/* Double Card Flip on Click Animation */

.card__inner_downgrade_modal {
  width: 170.5px;
  height: 234.5px;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card__inner_downgrade_modal.is-flipped {
  transform: rotateY(180deg);
}


.card__face_downgrade_modal {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.card__face_downgrade_modal--front {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}

.card__face_downgrade_modal--back {
  padding-left: 10px;
  transform: rotateY(180deg) scaleX(1);
}

/* Double Card Flip on Click Animation */

/* Flip Animations START */
.flip {
  backface-visibility: visible !important;
  animation: flip 2s ease forwards;
}

@keyframes flip {
  0% {
    transform: perspective(1500px) rotateY(0);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(1500px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(1500px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(1500px) rotateY(360deg) scale(.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(1500px) scale(1);
    animation-timing-function: ease-in;
  }
}

/* Flip Animations END*/

/* Hi There Animation START */
.hithere {
  animation: hithere 1s ease infinite;
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Hi There Animation END */

.resultCardMainImageWrapperImg:hover {
  z-index: 3000;
}

/* .resultCardMainImageWrapperImg{
  transition: transform .2s;
}
.resultCardMainImageWrapperImg:hover {
  height: 469px;
  width: 321px;
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5); 
  -webkit-transform: scale(1.5); 
  transform: scale(1.5);
} */

/* .resultCardMainNumLine5 {
  position: absolute;
  height: 1px;
  width: 78px;
  background-color: #fff;
  top: 119px;
  left: 305px;
  transform: rotate(6.9deg);
} */

/* Short Result Personality Card */
.resultCardETextShort {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 115px;
  left: 170px;
}

.resultCardOTextShort {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 115px;
  left: 230px;
}

.resultCardNTextShort {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 184px;
  left: 170px;
}

.resultCardATextShort {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 194px;
  left: 200px;
}

.resultCardCTextShort {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 184px;
  left: 230px;
}

.resultCardETextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: -40px;
  left: 124px;
}

.resultCardOTextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: -40px;
  left: 186px;
}

.resultCardNTextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 34px;
  left: 124px;
}

.resultCardATextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 44px;
  left: 157px;
}

.resultCardCTextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 34px;
  left: 188px;
}

.resultCardDarkNTextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 7px;
  left: 141px;
}

.resultCardDarkPTextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 7px;
  left: 170px;
}

.resultCardDarkMTextMain {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: -19px;
  left: 155px;
}


.resultCardEText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 170px;
  left: 444px;
}

.resultCardOText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 170px;
  left: 504px;
}

.resultCardNText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 245px;
  left: 444px;
}

.resultCardAText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 253px;
  left: 478px;
}

.resultCardCText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 245px;
  left: 504px;
}

/* Personality Values in Dark Triad Component Start */
.resultCardEText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 120px;
  left: 428px;
}

.resultCardOText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 120px;
  left: 490px;
}

.resultCardNText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 196px;
  left: 428px;
}

.resultCardAText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 205px;
  left: 462px;
}

.resultCardCText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 196px;
  left: 493px;
}

/* Personality Values in Dark Triad Component End */

.resultCardDarkNText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 215px;
  left: 461px;
}

.resultCardDarkMText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 190px;
  left: 477px;
}

.resultCardDarkPText {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 215px;
  left: 491px;
}

.resultCardDarkMText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 142px;
  left: 462px;
}

.resultCardDarkNText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 168px;
  left: 445px;
}

.resultCardDarkPText2 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  top: 168px;
  left: 475px;
}

.resultCardMainLine {
  display: none;
  position: absolute;
  height: 1px;
  width: 70px;
  background-color: #fff;
  top: -64px;
  left: -20px;
  transform: rotate(15.9deg);
}

.resultCardMain1Text {
  display: none;
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: -107px;
  left: -117px;
}

.resultCardMainLine2 {
  display: none;
  position: absolute;
  height: 1px;
  width: 280px;
  background-color: #fff;
  top: 214px;
  left: -370px;
  transform: rotate(0.9deg);
}

.resultCardMain1Text2 {
  display: none;
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 172px;
  right: 740px;
}

.resultCardMainLine3 {
  display: none;
  position: absolute;
  height: 1px;
  width: 107px;
  background-color: #fff;
  top: 8px;
  right: 10px;
  transform: rotate(6.9deg);
}

.resultCardMain1Text3 {
  display: none;
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 1px;
  right: -105px;
}

.resultCardMainLine4 {
  display: none;
  position: absolute;
  height: 1px;
  width: 140px;
  background-color: #fff;
  top: 172px;
  right: 8px;
  transform: rotate(6.9deg);
}

.resultCardMain1Text4 {
  display: none;
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 169px;
  right: -120px;
}

.resultCardMainLine5 {
  position: absolute;
  height: 1px;
  width: 78px;
  background-color: #fff;
  top: 110px;
  left: 285px;
  transform: rotate(10.3deg);
}

.resultCardMain1Text5 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 70px;
  left: 109px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMain1Text5Anim {
  animation: animated-text 2s steps(50, end) 0s 0.2 normal both;
}

.resultCardMain1Text5AnimOpenness {
  animation: animated-text 2s steps(50, end) 0s 0.2 alternate forwards;
}

.resultCardMain1Text5AnimConscientiousness {
  animation: animated-text 2s steps(50, end) 0s 0.27 alternate forwards;
}

.personalityTraitsDarkTriadMachiavellianismAnim {
  animation: animated-text 2s steps(50, end) 0s 0.27 normal both;
}

.personalityTraitsDarkTriadNarcissismAnim {
  animation: animated-text 2s steps(50, end) 0s 0.2 normal both;
}

.personalityTraitsDarkTriadPsychopathyAnim {
  animation: animated-text 2s steps(50, end) 0s 0.23 alternate forwards;
}

.resultCardMainLine6 {
  position: absolute;
  height: 1px;
  width: 70px;
  background-color: #fff;
  top: 333px;
  left: 295px;
  transform: rotate(-15.1deg);
}

.resultCardMain1Text6 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 333px;
  left: 100px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMainLine7 {
  position: absolute;
  height: 1px;
  width: 72px;
  background-color: #fff;
  top: 443px;
  left: 448px;
  transform: rotate(89.9deg);
}

.resultCardMain1Text7 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 480px;
  left: 378px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMainLine8 {
  position: absolute;
  height: 1px;
  width: 67px;
  background-color: #fff;
  top: 330px;
  right: 295px;
  transform: rotate(9.9deg);
}

.resultCardMain1Text8 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 345px;
  left: 700px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMainLine9 {
  position: absolute;
  height: 1px;
  width: 67px;
  background-color: #fff;
  top: 100px;
  right: 298px;
  transform: rotate(-19.9deg);
}

.resultCardMain1Text9 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 53px;
  left: 740px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMainLine10 {
  position: absolute;
  height: 1px;
  width: 169px;
  background-color: #fff;
  top: 140px;
  left: 294px;
  transform: rotate(15.9deg);
}

.resultCardMain1Text10 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 78px;
  left: 70px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMainLine11 {
  position: absolute;
  height: 1px;
  width: 106px;
  background-color: #fff;
  top: 271px;
  left: 300px;
  transform: rotate(-18.1deg);
}

.resultCardMain1Text11 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 279px;
  left: 120px;
  white-space: nowrap;
  overflow: hidden;
}

.resultCardMainLine12 {
  position: absolute;
  height: 1px;
  width: 120px;
  background-color: #fff;
  top: 238px;
  right: 290px;
  transform: rotate(-18.1deg);
}

.resultCardMain1Text12 {
  position: absolute;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  top: 183px;
  left: 637px;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 995px) {
  .modalEmailText {
    float: none;
  }

  .modalEmailSignupButton {
    float: none;
  }

  .modalEmailFooterCol1 {
    text-align: center;
    align-items: center;
  }

  .modalEmailFooterCol2 {
    text-align: center;
    align-items: center;
  }

  .personalityCardImageFontSize {
    font-size: 1.2vw !important;
    font-weight: normal;
  }

  .resultCardETextMain {
    font-size: 10px;
    font-weight: 600;
    top: -26px;
    left: 73px;
  }

  .resultCardOTextMain {
    font-size: 10px;
    font-weight: 600;
    top: -26px;
    left: 118px;
  }

  .resultCardNTextMain {
    font-size: 10px;
    font-weight: 600;
    top: 26px;
    left: 73px;
  }

  .resultCardATextMain {
    font-size: 10px;
    font-weight: 600;
    top: 35px;
    left: 97px;
  }

  .resultCardCTextMain {
    font-size: 10px;
    font-weight: 600;
    top: 26px;
    left: 118px;
  }

  .resultCardDarkNTextMain {
    font-size: 10px;
    font-weight: 600;
    top: 7px;
    left: 86px;
  }

  .resultCardDarkPTextMain {
    font-size: 10px;
    font-weight: 600;
    top: 7px;
    left: 107px;
  }

  .resultCardDarkMTextMain {
    font-size: 10px;
    font-weight: 600;
    top: -10px;
    left: 97px;
  }


  .resultCardMain1 {
    margin-right: 0px;
    min-height: 350px !important;
    width: 240 !important;
    height: 350 !important;
  }

  .resultCardMain2 {
    margin-left: 0px;
    max-height: 250px;
    object-fit: contain;
  }

  .resultCardMainLine {
    width: 47px;
    top: -44px;
    left: 7px;
  }

  .resultCardMain1Text {
    font-size: 18px;
    font-weight: 500;
    width: 30px;
    top: -83px;
    left: -63px;
  }

  .resultCardMainLine2 {
    width: 280px;
    top: 210px;
    left: -340px;
  }

  .resultCardMain1Text2 {
    font-size: 18px;
    font-weight: 500;
    top: 185px;
    left: -390px;
  }

  .resultCardMainLine3 {
    width: 105px;
    top: 11px;
    left: 235px;
  }

  .resultCardMain1Text3 {
    font-size: 18px;
    width: 20px;
    font-weight: 500;
    top: 5px;
    left: 345px;
  }

  .resultCardMainLine4 {
    width: 150px;
    top: 172px;
    left: 200px;
  }

  .resultCardMain1Text4 {
    font-size: 18px;
    font-weight: 500;
    width: 30px;
    top: 170px;
    left: 350px;
  }

  .resultCard2Main {
    max-height: 250px;
    object-fit: contain;
  }

  /* Personality traits */
  .resultCardMainLine5 {
    position: absolute;
    /* height: 1px; */
    /* width: 42px; */
    top: 75px;
    left: 307px;
    /* background-color: #fff; */
    transform: rotate(6.9deg);
    display: none;
  }

  .resultCardMain1Text5 {
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    top: 60px;
    left: 135px;
    display: none;
  }

  .resultCardMainLine6 {
    position: absolute;
    /* width: 42px; */
    top: 255px;
    left: 317px;
    display: none;
  }

  .resultCardMain1Text6 {
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    top: 260px;
    left: 145px;
    display: none;
  }

  .resultCardMainLine7 {
    position: absolute;
    width: 50px;
    top: 335px;
    left: 460px;
    display: none;
  }

  .resultCardMain1Text7 {
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    top: 363px;
    left: 400px;
    display: none;
  }

  .resultCardMainLine8 {
    position: absolute;
    /* width: 47px; */
    top: 256px;
    right: 323px;
    display: none;
  }

  .resultCardMain1Text8 {
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    top: 255px;
    right: 100px;
    display: none;
  }

  .resultCardMainLine9 {
    position: absolute;
    /* width: 40px; */
    top: 81px;
    right: 325px;
    display: none;
  }

  .resultCardMain1Text9 {
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    top: 78px;
    right: 175px;
    display: none;
  }

  .worldViewImg {
    max-height: 250px;
    margin-left: 20px;
    width: 70px;
    object-fit: contain;
  }

  .darkTraidImg {
    max-height: 250px;
    object-fit: contain;
  }

  .resultCardMainLine10 {
    width: 122px;
    top: 100px;
    left: 213px;
  }

  .resultCardMain1Text10 {
    font-size: 18px;
    font-weight: 500;
    top: 76px;
    left: 15px;
  }

  .resultCardMainLine11 {
    width: 92px;
    top: 206px;
    left: 202px;
  }

  .resultCardMain1Text11 {
    font-size: 18px;
    font-weight: 500;
    top: 213px;
    left: 59px;
  }

  .resultCardMainLine12 {
    width: 84px;
    top: 177px;
    right: 213px;
  }

  .resultCardMain1Text12 {
    font-size: 18px;
    font-weight: 500;
    top: 144px;
    right: 54px;
  }
}

@media only screen and (max-width: 768px) {
  .DownloadLinkColumn1 {
    text-align: center;
  }

  .DownloadLinkColumn2 {
    text-align: center;
  }

  .personalityCardImageFontSize {
    font-size: 1.4vw !important;
    font-weight: normal;
  }

  .resultCardMain1 {
    margin-right: 0px;
    min-height: 350px !important;
    margin: 0 auto;
    width: 240 !important;
    height: 350 !important;
  }

  .resultCardMain2 {
    display: block;
    margin-left: 0px;
    max-height: 250px;
    object-fit: contain;
    margin-top: 10px;
    margin: 0 auto;
  }

  .resultCardMainLine {
    display: none;
    width: 47px;
    top: 81px;
    left: -10px;
  }

  .resultCardMain1Text {
    display: none;
    font-size: 14px;
    font-weight: 500;
    top: 53px;
    left: -73px;
  }

  .resultCardMainLine2 {
    display: none;
    width: 225px;
    top: 165px;
    left: -265px;
  }

  .resultCardMain1Text2 {
    display: none;
    font-size: 14px;
    font-weight: 500;
    top: 145px;
    left: -299px;
  }

  .resultCardMainLine3 {
    display: none;
    width: 85px;
    top: 14px;
    right: -89px;
  }

  .resultCardMain1Text3 {
    display: none;
    font-size: 14px;
    font-weight: 500;
    width: 40px;
    top: 10px;
    left: 277px;
  }

  .resultCardMainLine4 {
    display: none;
    width: 120px;
    top: 134px;
    right: -88px;
  }

  .resultCardMain1Text4 {
    display: none;
    font-size: 14px;
    font-weight: 500;
    top: 129px;
    left: 280px;
    width: 40px;
  }

  .resultCardMainLine5 {
    width: 52px;
    top: 78px;
    left: 335px;
  }

  .resultCardMain1Text5 {
    font-size: 14px;
    font-weight: 500;
    top: 64px;
    left: 205px;
  }

  .resultCardMainLine6 {
    width: 52px;
    top: 255px;
    left: 335px;
  }

  .resultCardMain1Text6 {
    font-size: 14px;
    font-weight: 500;
    top: 256px;
    left: 208px;
  }

  .resultCardMainLine7 {
    width: 52px;
    top: 338px;
    left: 458px;
  }

  .resultCardMain1Text7 {
    font-size: 14px;
    font-weight: 500;
    top: 369px;
    left: 405px;
  }

  .resultCardMainLine8 {
    width: 52px;
    top: 255px;
    right: 335px;
  }

  .resultCardMain1Text8 {
    font-size: 14px;
    font-weight: 500;
    top: 260px;
    right: 163px;
  }

  .resultCardMainLine9 {
    width: 52px;
    top: 91px;
    right: 337px;
  }

  .resultCardMain1Text9 {
    font-size: 14px;
    font-weight: 500;
    top: 104px;
    right: 239px;
  }

  .resultCardMainLine10 {
    width: 122px;
    top: 100px;
    left: 121px;
  }

  .resultCardMain1Text10 {
    font-size: 14px;
    font-weight: 500;
    top: 75px;
    left: -35px;
  }

  .resultCardMainLine11 {
    width: 95px;
    top: 202px;
    left: 115px;
  }

  .resultCardMain1Text11 {
    font-size: 14px;
    font-weight: 500;
    top: 222px;
    left: 14px;
  }

  .resultCardMainLine12 {
    width: 94px;
    top: 175px;
    right: 114px;
  }

  .resultCardMain1Text12 {
    font-size: 14px;
    font-weight: 500;
    top: 144px;
    right: -7px;
  }
}

@media only screen and (max-width: 660px) {

  .personalityCardImageFontSize {
    font-size: 1.7vw !important;
    font-weight: normal;
  }
}

@media only screen and (max-width: 573px) {
  /* .modalEmail svg {
    right: 0% !important;
  } */

  .modalDowngrade svg {
    right: 0% !important;
  }
  .modalCongrats svg {
    right: 0% !important;
  }

  .resultCardMainLine2 {
    width: 230px;
    top: -180px;
    left: 0px;
  }

  .resultCardMain1Text2 {
    font-size: 14px;
    font-weight: 500;
    top: -195px;
    left: -23px;
  }

  /* Dark Triad */
  .resultCardMainLine10 {
    width: 119px;
    top: 104px;
    left: 118px;
    display: none;
  }

  .resultCardMain1Text10 {
    font-size: 10px;
    font-weight: 500;
    top: 76px;
    left: 11px;
    display: none;
  }

  .resultCardMainLine11 {
    width: 79px;
    top: 204px;
    left: 112px;
    display: none;
  }

  .resultCardMain1Text11 {
    font-size: 10px;
    font-weight: 500;
    top: 220px;
    left: 28px;
    display: none;
  }

  .resultCardMainLine12 {
    width: 82px;
    top: 178px;
    right: 110px;
    display: none;
  }

  .resultCardMain1Text12 {
    font-size: 10px;
    font-weight: 500;
    top: 148px;
    right: 20px;
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .personalityCardImageFontSize {
    font-size: 2.0vw !important;
    font-weight: normal;
  }
}


@media only screen and (max-width: 440px) {

  .modalEmail {
    margin-top: 65px;
  }

  .modalMint {
    margin-top: 65px
  }

  .modalDowngrade {
    margin-top: 65px;
  }
  .modalCongrats {
    margin-top: 65px;
  }

  .modalEmailText {
    width: 100%;
  }

  .personalityCardImageFontSize {
    font-size: 2.6vw !important;
    font-weight: normal;
  }

  .resultCardMain1 {
    margin-right: 0px;
    min-height: 350px !important;
    margin: 0 auto;
    width: 240 !important;
    height: 350 !important;
  }

  .resultCard2Main {
    max-height: 170px;
    object-fit: contain;
  }

  .resultCardMainLine {
    width: 47px;
    top: 81px;
    left: 8px;
  }

  .resultCardMain1Text {
    font-size: 14px;
    font-weight: 500;
    width: 50px;
    top: 38px;
    left: -28px;
  }



  .resultCardMainLine5 {
    width: 45px;
    top: 72px;
    left: 60px;
  }

  .resultCardMain1Text5 {
    font-size: 14px;
    font-weight: 500;
    top: 50px;
    left: 10px;
  }

  .resultCardMainLine6 {
    width: 42px;
    top: 252px;
    left: 60px;
  }

  .resultCardMain1Text6 {
    font-size: 14px;
    font-weight: 500;
    top: 270px;
    left: 5px;
  }

  .resultCardMainLine7 {
    width: 43px;
    top: 330px;
    left: 180px;
  }

  .resultCardMain1Text7 {
    font-size: 14px;
    font-weight: 500;
    top: 360px;
    left: 150px;
  }

  .resultCardMainLine8 {
    width: 40px;
    top: 265px;
    right: 65px;
  }

  .resultCardMain1Text8 {
    font-size: 10px;
    font-weight: 500;
    top: 275px;
    right: 5px;
  }

  .resultCardMainLine9 {
    width: 43px;
    top: 95px;
    right: 65px;
  }

  .resultCardMain1Text9 {
    font-size: 14px;
    font-weight: 500;
    top: 102px;
    right: 10px;
  }

  .darkTraidImg {
    max-height: 160px;
    object-fit: contain;
  }

  .resultCardMainLine10 {
    width: 119px;
    top: 104px;
    left: 118px;
    display: none;
  }

  .resultCardMain1Text10 {
    font-size: 10px;
    font-weight: 500;
    top: 76px;
    left: 11px;
    display: none;
  }

  .resultCardMainLine11 {
    width: 79px;
    top: 204px;
    left: 112px;
    display: none;
  }

  .resultCardMain1Text11 {
    font-size: 10px;
    font-weight: 500;
    top: 220px;
    left: 28px;
    display: none;
  }

  .resultCardMainLine12 {
    width: 82px;
    top: 178px;
    right: 110px;
    display: none;
  }

  .resultCardMain1Text12 {
    font-size: 10px;
    font-weight: 500;
    top: 148px;
    right: 20px;
    display: none;
  }

}

@media only screen and (max-width: 340px) {
  .modalEmail svg {
    top: 0% !important;
  }

  .modalEmail {
    margin-top: 105px;
  }

  .modalMint {
    margin-top: 105px;
  }

  .modalDowngrade svg {
    top: 0% !important;
  }

  .modalDowngrade {
    margin-top: 105px;
  }

  .modalCongrats svg {
    top: 0% !important;
  }

  .modalCongrats {
    margin-top: 105px;
  }

  .personalityCardImageFontSize {
    font-size: 4.8vw !important;
    font-weight: normal;
  }

  .modalEmailSignupButton {
    width: 100%;
  }
}

/* **************** */
/* positioning end */
/* ***************** */
.resultWelcomeHead {
  font-family: var(--mont);
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.01rem;
  text-align: left;
}

.resultWelcomePara {
  font-size: 20px;
  font-family: var(--mont);
  color: #fff;
  line-height: 1.6em;
}

.resultWelcomeTags {
  font-size: 20px;
  font-family: var(--mont);
  color: #fff;
  line-height: 1.5;
  word-wrap: break-word;
  word-break: break-all;
}

.resultYourResultHeading {
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  font-size: 38px;
  /* white-space: nowrap; */
  overflow: hidden;
  letter-spacing: 7px;
}
.resultYourResultHeadingAnim {
  animation: animated-text 1s steps(50, end) 0s 1 normal both;

}

@keyframes animated-text {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.customFooterNav .btn {
  width: 100% !important;
  min-height: 100% !important;
  background: black !important;
  height: 60px !important;
  border-top: white 1px solid !important;
  border-left: white 2px solid !important;
  border-radius: 0px !important;
  background-color: black !important;
}

.resultFirstLine {
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  line-height: 35px;
  font-weight: 600;
}

.resultFirstLine span {
  font-weight: normal !important;
}

.mintButtonRed {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 75%;
  height: 70% !important;
  border-radius: 7px;
  text-align: center;
  letter-spacing: 3px;
}

.mintButtonRed:hover {
  background-color: #C8E0FE;
}

.mintButtonRedHalf {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  /* width: 40%; */
  /* margin-right: 5%; */
  height: 70% !important;
  border-radius: 7px;
  text-align: center;
  letter-spacing: 3px;

  /* width: 50% !important; */
  margin-bottom: 5%;
  padding-left: 15px;
  padding-right: 15px;
}

.mintButtonRedHalf:hover {
  background-color: #C8E0FE;
}

.mintButtonRedNextSteps1 {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2.5px;
}

.mintButtonRedNextSteps1:hover {
  background-color: #C8E0FE;
}

.mintButtonRedNextSteps2 {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2.5px;
}

.mintButtonRedNextSteps2:hover {
  background-color: #C8E0FE;
}

.mintButtonRedNextSteps3 {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2.5px;
}

.mintButtonRedNextSteps3:hover {
  background-color: #C8E0FE;
}

.mintButtonRedCommunity1 {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* max-height: 38px; */
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2.5px;
}

.mintButtonRedCommunity1:hover {
  background-color: #C8E0FE;
}

.mintButtonRedCommunity2 {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #ff4040;
  border: 0;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2.5px;
  max-height: 38px;

}

.mintButtonRedCommunity2:hover {
  background-color: #C8E0FE;
}

.mintButtonGreen {
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background-color: #00c241;
  border: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2.5px;
}

.mintButtonGreen:hover {
  background-color: #C8E0FE;
}

.mintButtonsSmallPara {
  color: #fff;
  font-family: var(--mont);
  font-size: 12px;
  font-weight: 600px;
  text-align: center;
  letter-spacing: 1.5px;
}
.mintButtonsSmallParaCard {
  color: #fff;
  font-family: var(--mont);
  font-size: 12px;
  font-weight: 600px;
  letter-spacing: 1.5px;
}

.mintButtonsGiftHeading {
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.resultSecondLine {
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  line-height: 35px;
  font-weight: 500;
}

.mintButtonWhite {
  font-size: 20px;
  font-weight: bolder;
  color: #ff4040;
  background-color: #fff;
  border: 0;
  /* height: 45px; */
  line-height: 1.5;
  padding-top: 4px;
  padding-bottom: 4px;
  /* width: 90%; */
  border-radius: 7px;
  text-align: center;
  letter-spacing: 2px;
}

.mintButtonWhite:hover {
  background-color: #C8E0FE;
}

.resultPersonality {
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  font-size: 40px;
  text-align: center;
}

.resultPersonalityHead {
  color: #fff;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 25px;
  white-space: nowrap;
  overflow: hidden;
}

/* Animations */
.rotate {
  transform: rotateY(0deg);
  animation: rotation 5s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(359deg);
  }
}

.rotateOnce {
  transform: rotateY(90deg);
  animation: rotation 1s linear forwards;
}

@keyframes rotation {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}



.resultPersonalitypara {
  color: #fff;
  font-family: var(--mont);
  font-size: 20px;
}

.resultExtroversionHead {
  font-size: 22px;
  font-family: var(--mont);
  font-weight: bold;
  color: #fff;
  height: 50px;
  /* border: 1px solid #000000; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}



.resultExtroversionPara {
  font-size: 20px;
  line-height: 1.6em;
  font-family: var(--mont);
  color: #fff;
  text-align: left;
}

.resultExtroversiondesc {
  text-align: left;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px !important;
  white-space: nowrap;
  overflow: hidden;
}

.resultExtroversiondesc2 {
  text-align: left;
  color: #fff;
  font-family: var(--mont);
  font-size: 15px;
  /* font-weight: bold; */
  margin-top: 10px !important;
}

.resultExtroversionpionts {
  color: #fff;
  text-align: left;
  font-family: var(--mont);
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

}

.resultExtroversionpiontsItem {
  flex: 45% !important;
  margin-left: 25px;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.resultSubtraitsBullets {
  color: #fff;
  text-align: left;
  font-family: var(--mont);
  font-size: 20px;
  margin-left: 50px !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.resultSubtraitsBulletsItem {
  flex: 45% !important;
  margin-left: 25px;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.resultTraitCombHead {
  color: #fff;
  text-align: left;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}

.resultTraitCombPara {
  color: #fff;
  text-align: left;
  font-family: var(--mont);
  font-size: 20px;
}

.resultDarkTraid {
  color: #fff;
  font-family: var(--mont);
  font-size: 40px;
  font-weight: bold;
  /* white-space: nowrap;
  overflow: hidden; */
}


.resultDarkTraidHead {
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  font-family: var(--mont);
  white-space: nowrap;
  overflow: hidden;
}

.resultDarkTraidPara {
  font-size: 20px;
  line-height: 1.6em;
  font-family: var(--mont);
  /* font-weight: bold; */
  color: #fff;
}

.resultDarkNarcisim {
  font-size: 22px;
  color: #fff;
  font-family: var(--mont);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}

.resultDarkNarcisimPara {
  font-size: 20px;
  color: #fff;
  font-family: var(--mont);
  line-height: 1.6em;
}

.resultWorldsView {
  font-family: var(--mont);
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

.resultWorldsViewSubHead {
  font-family: var(--mont);
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 30px;
}


.resultWorldsViewPara {
  margin-left: 30px;
  font-size: 22px;
  font-family: var(--mont);
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}


.resultSystemValue {
  font-size: 22px;
  font-family: var(--mont);
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}

.resultSystemValuePara {
  font-size: 20px;
  color: #fff;
  font-family: var(--mont);
  line-height: 1.6em;
}

.resultSystemValuePara ul {
  padding-left: 25px;
}

.resultNextStep {
  font-size: 40px;
  font-family: var(--mont);
  font-weight: bold;
  color: #fff;
}

.resultNextStepHead {
  font-size: 22px;
  font-family: var(--mont);
  color: #fff;
  font-weight: bold;
  text-align: left;
}

.resultNextStepPara {
  font-size: 20px;
  color: #fff;
  font-family: var(--mont);
  line-height: 1.6em;
  text-align: left;
}

.resultNextStepSubPara {
  font-size: 20px;
  color: #fff;
  font-family: var(--mont);
  line-height: 1.6em;
  text-align: left;
}

.resultTableHeading {
  font-size: 17px;
  color: #fff;
  font-family: var(--mont);
  line-height: 1.6em;
  font-weight: bold;
}

.resultTableData {
  font-size: 16px;
  color: #fff;
  font-family: var(--mont);
  line-height: 1.6em;
}

@media only screen and (max-width: 768px) {

  .testPageHeading {
    font-size: 35px;
  }

  .resultPersonality {
    font-size: 30px;
  }

  .resultPersonalityHead {
    font-size: 20px;
  }

  .resultDarkTraid {
    font-size: 30px;
  }

  .resultWorldsView {
    font-size: 30px;
  }

  .resultWorldsViewSubHead {
    font-size: 25px;
  }

  .resultNextStep {
    font-size: 30px;
  }

  .modal-backdrop {
    background-color: transparent;
  }

}

@media only screen and (max-height: 700px) {
  .modalEmail {
    margin-top: 65px;
  }

  .modalMint {
    margin-top: 65px;
  }

  .modalDowngrade {
    margin-top: 15px;
  }
  .modalCongrats {
    margin-top: 15px;
  }
}

/* results page end*/
