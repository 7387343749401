.mainBoxWrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}

.mainBox {
  /* max-width: 980px !important;
  width: 980px !important; */
  /* overflow-y: scroll; */
  /* height: 78vh !important; */
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 30px 30px;
  /* margin: auto; */

  position: relative !important;
}

/* copied */
.rotateNow {
  transform: rotateY(90deg);
  animation: rotation 1s linear forwards;
}

@keyframes rotation {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

/* simple report page */
.reportHead {
  font-weight: bold !important;
  font-family: var(--mont);
  color: #fff;
  font-size: 40px;
  letter-spacing: 0.2rem;
}

.reportPara {
  letter-spacing: 0.1rem;
  max-width: 515px !important;
  font-family: var(--mont);
  color: #fff;
  font-size: 30px;
  margin: 50px auto;
}

.reportBtn {
  color: #000 !important;
  background-color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  letter-spacing: 1.5px;
  font-size: 25px !important;
  border-radius: 15px !important;
  padding: 8px 50px !important;
}

.reportEnter {
  color: #fff;
  font-family: var(--mont);
  font-size: 21px;
  padding-left: 10px;
}

.reportEnter span {
  font-weight: bold;
}

.reportEnter>span>img {
  max-width: 20px;
}

.cardImage {
  max-width: 90% !important;
  height: auto;
}

@media only screen and (max-width: 1370px) {
  .cardImage {
    max-width: 60% !important;
    height: auto;
  }

  .reportHead {
    font-size: 30px;
  }

  .reportPara {
    max-width: 515px !important;
    font-size: 20px;
    margin: 30px auto;
  }

  .reportBtn {
    font-weight: bold !important;
    letter-spacing: 1.3px;
    font-size: 20px !important;
    border-radius: 10px !important;
    padding: 8px 30px !important;
  }
}

@media only screen and (max-width: 995px) {
  .mainBox {
    width: 100% !important;
  }
}

@media only screen and (max-height: 756px) and (max-width: 1040px) {
  .mainBoxWrapper {
    width: 100%;
    height: 100%;
    display: inline !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .reportHead {
    font-size: 25px;
  }

  .reportPara {
    font-size: 20px;
    margin: 10px auto;
  }

  .reportBtn {
    font-weight: bold !important;
    font-size: 20px !important;
    border-radius: 10px !important;
    padding: 5px 30px !important;
  }

  .cardImage {
    max-height: 200px !important;
    object-fit: contain !important;
  }
}

/* ********************* */
/* simple report page */
/* copied end */
