/* Community Page Start */

.communityContainer {
  display: flex;
  flex-direction: column;
}

.communityContainer .mintButtonRedCommunity1 {
  max-width: 400px;
}

.communityContainer .registrationGroup {
  max-width: 400px;
  padding-left: 0px;
  padding-right: 0px;
}

.communityContainer .mintButtonRedCommunity1 {
  /* max-height: 38px; */
  font-weight: 400;
}

.registerButton {
  border: 1px solid black !important;
  background-color: #28c928 !important;
  color: black !important;
}

.connectedButton {
  border: 1px solid gray !important;
  border-radius: 5px;
  color: white !important;
  background-color: green !important;
  max-width: 120px;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: var(--mont) !important;
  text-align: center;
  width: 200px !important;
}

.communityToolsButton {
  padding: 0px !important;
  height: 17px !important;
  font-size: 0.6rem !important;
  width: 100px !important;
}

.communityPageHeading {
  margin-top: 65px;
  font-family: var(--mont);
  font-size: 52px;
  letter-spacing: 0.01em;
  color: #fff;
  font-weight: bold;
}
.communityCommonParas {
  font-size: 20px;
  font-family: var(--mont);
  color: #fff;
  line-height: 2.6em;
}
.communityCardMain {
  margin-right: 25px;
  width: 240 !important; 
  height: 350 !important;
}
.communityLinksHeading {
  color: #fff;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 40px;
  text-align: left;
}
.communityLinksSecondHeading {
  text-align: left !important;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-bottom: 2px;
}

.communityLinksSubHeading {
  text-align: left !important;
  color: #fff;
  font-family: var(--mont);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-bottom: 0px;

}
.communityLinksSecondHeadingLink {
  text-align: left !important;
  color: #fff;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  float: left;
  clear: left;
}
.communityCommonParas2 {

  float: left;
  clear: left;
  text-align: left;
  color: #fff;
  font-family: var(--mont);
  font-size: 12px;
}

.connectionsTable th {
  font-size: 15px !important;

}

.connectionsTable td {
}

.connectionsTable tr {
  font-size: 11px;
}

.connectionsTable th, td {
  border: 1px solid gray !important;
  min-width: 120px;
  font-weight: 300;
  letter-spacing: 0.1em;
  padding: 0.75rem !important;

}

@media only screen and (max-width: 995px) {
  .communityCardMain {
    margin-right: 0px;
    max-height: 250px;
    object-fit: contain;
  }
}
@media only screen and (max-width: 768px) {
  .communityPageHeading {
    font-size: 35px;
  } 
  .communityCardMain {
    margin-right: 0px;
    max-height: 250px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }
  .communityLinksHeading {
    font-size: 30px;
  }
}

@media only screen and (max-width: 440px) {
  
  .communityCommonParas2 {
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 995px) {
  .communityTools {
    margin-top: -20px;
  }
}
