.mainBoxWrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}
.mainBox {
  max-width: 980px !important;
  width: 980px !important;
  /* overflow-y: scroll; */
  height: 78vh !important;
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 30px 30px;
  padding-right: 5px;
  padding-left: 5px;
  /* margin: auto; */

  position: relative !important;
}
.mainBoxAfter {
  overflow-y: scroll;
  height: 78vh !important;
  padding: 0px 0px 50px 0px !important;
}
/* scroll bar */
.mainBoxAfter::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.mainBoxAfter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(228, 228, 228);
  border-radius: 10px;
  height: 80px;
}

/* Handle */
.mainBoxAfter::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 10px;
  border-right: solid 5px transparent;
}

/* Handle on hover */
.mainBoxAfter::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.mainBoxAfter::-webkit-scrollbar-button {
  height: 22px;
}
/* scroll bar */
@media only screen and (max-width: 995px) {
  .mainBox {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .mainBoxAfter::-webkit-scrollbar {
    width: 0px;
  }
  .mainBox {
    padding-right: 0px;
    padding-left: 0px;
  }
}

/* mint card page */
.mintMain {
  color: #fff;
  text-align: center;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 52px;
  margin-top: 20px;
}
.mintSubHead {
  font-size: 34px;
  font-weight: bold;
  font-family: var(--mont);
  color: #fff;
}
.mintSubPara {
  color: #fff;
  font-size: 22px;
  font-family: var(--mont);
  font-weight: bold;
  margin-bottom: 40px !important;
  margin-left: 30px;
}
.btnMint {
  margin-left: 30px;
  background-color: rgba(255, 64, 64) !important;
  color: #fff !important;
  font-family: var(--mont);
  font-weight: bold !important;
  font-size: 22px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.btnMint:hover {
  background-color: rgba(0, 87, 225) !important;
}
.mintWhiteLine {
  height: 1px;
  background-color: #fff;
  max-width: 575px !important;
}
.mintPersonality {
  color: #fff;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  font-family: var(--mont);
}
.mintPersonalityPara {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: var(--mont);
}
.btnmintpersonality {
  margin-left: 30px;
  background-color: rgba(240, 172, 172) !important;
  color: #fff !important;
  font-family: var(--mont);
  font-weight: bold !important;
  font-size: 22px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.btnmintpersonality:hover {
  background-color: rgb(0, 87, 225) !important;
}
@media only screen and (max-width: 768px) {
  .mintMain {
    font-size: 35px;
  }
  .mintSubHead {
    font-size: 25px;
  }
  .mintPersonality {
    font-size: 25px;
  }
}
/* mint card page end */
