.mainBoxWrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}
.mainBox {
  /* max-width: 980px !important;
  width: 980px !important; */
  /* overflow-y: scroll; */
  height: 78vh !important;
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 0px 0px 30px 30px;
  padding-right: 5px;
  padding-left: 5px;
  /* margin: auto; */

  position: relative !important;
}
.mainBoxAfter {
  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 78vh !important;
  padding: 0px 0px 50px 0px !important;
}
/* scroll bar */
.mainBoxAfter::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.mainBoxAfter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(228, 228, 228);
  border-radius: 10px;
  height: 80px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Handle */
.mainBoxAfter::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
  border-radius: 10px;
  border-right: solid 5px transparent;
  /* background-clip: content-box; */
}

/* Handle on hover */
.mainBoxAfter::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.mainBoxAfter::-webkit-scrollbar-button {
  height: 22px;
}
/* scroll bar */
.disabled {
  opacity: 0.4;
}
.whiteLine {
  border-bottom: 1px solid #ccc;
  max-width: 73%;
  margin: 30px auto;
}

/* copied */
.selectBoxWrapper {
  display: flex;
  justify-content: center;
  font-family: var(--mont);
}
.selectBox {
  width: 550px;
  /* border: 1px solid blue; */
}
.selectBoxBefore {
  height: 38px;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid white;
}
.selectBoxBefore h4 {
  color: white;
  font-family: var(--mont);
  margin-bottom: 0px !important;
  line-height: 35px;
  font-size: 20px;
}
.selectBoxBefore > h4 > .selectBoxBeforeSpan2 {
  display: none;
}
.test2QuestionHead {
  color: white;
  font-family: var(--mont);
  font-size: 30px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.reportBtn {
  color: #000 !important;
  background-color: #fff !important;
  font-family: var(--mont) !important;
  font-weight: bold !important;
  font-size: 30px !important;
  border-radius: 15px !important;
  padding: 10px 50px !important;
}
.cautionErrorImg
{
  height: 27px;
  margin-top: 45px;
  margin-right: -45px;
  float: right;
  align-self:flex-end;
}
.errorHeading {
  background-color: #EF0000;
  color: #fff !important;
  font-family: var(--mont);
  text-align: center;
  font-size: 1.7rem;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.questionMainDiv{
  max-width: 90%;
  margin: 0 auto;
}
.questionMainDivError{
  border: 1px #EF0000 solid;
}
.footerCodeSnippets{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 140px;
  color: white;
  text-align: center;
  height: 100%;
  background-color: #000;
}
@media only screen and (max-width: 995px) {
  .mainBox {
    width: 100% !important;
  }
  .whiteLine {
    max-width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .selectBoxBefore > h4 > .selectBoxBeforeSpan1 {
    display: none;
  }
  .selectBoxBefore > h4 > .selectBoxBeforeSpan2 {
    display: inline;
  }
  .reportBtn {
    font-size: 20px !important;
    border-radius: 10px !important;
    padding: 5px 30px !important;
  }
  .mainBoxAfter::-webkit-scrollbar {
    width: 0px;
  }
  .mainBox {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media only screen and (max-width: 680px) {
  .cautionErrorImg{
    display: none;
  }
  .questionMainDiv{
    width: 95%;
  }
}

/* test2 end */
/* *********************** */
/* copied end */
