/* mintCards Page Start */

.commonMintPass .iframeCard {
  
}

.commonMintPass {
  min-height: 400px;
  padding-top: 5px;
}

.mintModalInfoContainer {
  display: flex;
  flex-direction: column;
}

.mintButtonGroup {
  align-self: center;
}

.mintCardsPageHeading {
  margin-top: 10px;
  font-family: var(--mont);
  font-size: 5em;
  letter-spacing: 0.05em;
  color: #fff;
  font-weight: bold;
}

.mintCardsCommonParas {
  font-size: 20px;
  font-family: var(--mont);
  color: #fff;
  line-height: 2.6em;
}

.mintCardsCardMain {
  margin-right: 25px;
  width: 240 !important;
  height: 350 !important;
}

.mintCardsLinksHeadnig {
  color: #fff;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 34px;
  text-align: left;
}

.mintCardsLinksSecondHeading {
  color: #fff;
  font-family: var(--mont);
  font-size: 17px;
  font-weight: 600;
}

.loginYouAreInBlockSmallPara {
  color: #fff;
  font-family: var(--mont);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15em;
  line-height: 1.5;
}

.myNFTMainHeading {
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  font-size: 36px;
  letter-spacing: 0.2em;
}

.myNFTMainHeadingBold {
  position: relative;
  left: -4.4%;
  text-align: center;
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  font-size: 38px;
  letter-spacing: 0.08em;
}
.myNFTMainHeadingBoldMint {
  position: relative;
  left: -4.4%;
  text-align: center;
  color: #fff;
  font-family: var(--mont);
  font-weight: 500;
  font-size: 34px;
  letter-spacing: 0.08em;
}

.myNFTSecondHeading {
  color: #fff;
  font-family: var(--mont);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05em;
}
.myNFTSecondHeadingMint {
  color: #fff;
  font-family: var(--mont);
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.myNFTSecondHeadingMint span{
  font-weight: 400;
}
.myNFTThirdColumn {
  padding-right: 0px !important;
}

.myNFTHeadingEarnings {
  color: #fff;
  font-family: var(--mont);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.08em;
  width: 100%;
}

.lifeTimeEarningsMain {
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid #00368d5e;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  padding: 0 !important;
}

.myNFTHeadingEarningAmount {
  color: #fff;
  font-family: var(--mont);
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.15em;
}

.nftCardHolder {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}

.myNFTSeeDetailsEarningButton {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #757575;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;

  /* position: absolute;
  right: 0;
  margin-right: 25px; */
}

.myNFTSeeDetailsEarningButton:hover {
  background-color: #0057E1;
  color: #fff;
}

.lifeTimeEarningsWhiteBackground {
  border-radius: 10px;
  background: #fff;
  padding: 0 !important;
  width: 100%;
  max-width: 475px !important;
  margin: 0;
  justify-content: center;
  height: 35px;
  clear: left !important;
  display: block;
}

.myNFTHeadingAddressLabel {
  color: #757575;
  font-family: var(--mont);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  letter-spacing: 0.03em;
  width: 80%;
  text-align: left;
}

.myNFTSmallMessage {
  color: #fff;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.5;
}

.myNFTSmallMessage a {
  text-decoration: underline;
  color: #fff;
}

.myNFTMediaButtons {
  font-size: 20px !important;
  font-weight: 900 !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  width: 100%;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  letter-spacing: 0.03em;
  /* float: left; */
  clear: left;
  display: block;

}

.myNFTMediaButtons:hover {
  background-color: #0057E1;
  color: #fff !important;
}

.myNFTMediaButtonsRed {
  color: red !important;
}

.sperator {
  height: 0.2px;
  margin-left: 10%;
  margin-right: 10%;
  background-color: rgb(179, 179, 179)
}

.myNFTDowngradeRarityButton {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #fff;
  font-family: var(--mont) !important;
  background-color: rgb(255, 64, 64);
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 7px;
  text-align: center;
  clear: left;
  display: block;

}

.myNFTDowngradeRarityButton:hover {
  background-color: #fff;
  color: rgb(255, 64, 64);
  ;
}

.myNFTMintButton {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #fff;
  font-family: var(--mont) !important;
  background-color: rgb(255, 64, 64);
  border: 0;
  padding: 5px 60px;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  width: 100%;
  letter-spacing: 0.1em !important;

}

.myNFTMintButton:hover {
  background-color: #fff;
  color: rgb(255, 64, 64);
}
.myNFTMintButtonBack {
  font-size: 26px !important;
  font-weight: bold !important;
  color: #000;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  padding: 0 60px;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  width: 100%;

}

.myNFTMintButtonBack:hover {
  background-color: #000;
  color: #E5EBFA;
  ;
}

.contractAddress {
  color: #fff;
}

.selectCrypto {
  border: 1px solid #C33AC0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.selectCryptoDropdownRarity {
  border: 1px solid #C33AC0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 352px !important;
  background-color: #000;
  height: 46px;
  z-index: 1000;
  color: #fff;
}

.selectCryptoDropdown1 {
  border: 1px solid #C33AC0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 350px !important;
  background-color: #000;
  height: 46px;
  z-index: 1000;
  color: #fff;
}

.selectCryptoDropdown2 {
  border: 1px solid #C33AC0;
  cursor: pointer;
  position: absolute;
  width: 350px !important;
  background-color: #000;
  height: 46px;
  margin-top: 46px;
  z-index: 1000;
  color: #fff;
}

.selectCryptoDropdown3 {
  border: 1px solid #C33AC0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 350px !important;
  background-color: #000;
  height: 46px;
  margin-top: 92px;
  z-index: 1000;
  color: #fff;
}

.selectCryptoDropdown:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.selectCryptoColumn1 {
  padding-right: 0px !important;
}

.selectCryptoValueHeading {
  font-family: var(--mont);
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.05em;
}
.selectCryptoValueHeadingRarity {
  font-family: var(--mont);
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.05em;
}

.selectCryptoValueLabel {
  font-family: var(--mont);
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  margin-top: -10px;
  text-align: center;
}

.selectCryptoDropdownImage {
  display: block;
}

.myNFTMaxDiscount {
  color: #fff;
  font-family: var(--mont);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.07em;
  width: 100%;
  background-color: #000;
  border: 0.2px solid #c33a3a;
  border-radius: 4px;
}

.containerEpicMintPass {
  background-color: rgba(195, 58, 58, 0.4);
  width: 100%;
  border-radius: 18px;
}

.myNFTSmallMessageMintPass {
  color: #fff;
  font-family: var(--mont);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  width: 95%;
}

.redImageWorldView {
  background-color: rgba(195, 58, 58, 0.4);
  position: absolute;
  width: 321px !important;
  height: 469px !important;
  margin-top: -469px;
}

.blueImageWorldView {
  background-color: rgba(70, 85, 170, 0.4);
  position: absolute;
  width: 321px !important;
  height: 469px !important;
  margin-top: -469px;
}

.myNFTEpicMintPass {
  color: #fff;
  position: absolute;
  font-family: var(--mont);
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.05em;
  width: 80%;
  background-color: #000;
  border: 0.2px solid #c33a3a;
  border-radius: 4px;
  top: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.myNFTCommonMintPass {
  color: #fff;
  position: absolute;
  font-family: var(--mont);
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.05em;
  width: 80%;
  background-color: #000;
  border: 0.2px solid #3a4fc3;
  border-radius: 4px;
  top: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.mintCardsLinksSecondHeadingWalletOnly {
  color: #fff;
  font-family: var(--mont);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.07em;
}

.emailAccountId {
  text-overflow: ellipsis;
  /* padding-right: 5px; */
  word-wrap: break-word;
}

.mintCardsCommonParas2 {
  text-align: left;
  color: #fff;
  font-family: var(--mont);
  font-size: 16px;
}

.walletDiv {
  width: 100%;
  max-width: 430px !important;
}

@media only screen and (max-width: 767px) {
.walletDiv {
    width: 100%;
    max-width: 100% !important;
    margin-right: 0px !important;
  }
}


@media only screen and (min-width: 768px) {
  .mintCardsComingSoonButton {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #0057E1;
    font-family: var(--mont) !important;
    background-color: #E5EBFA;
    border: 0;
    width: 100%;
    max-width: 430px !important;
    /* margin: 0 auto; */
    border-radius: 7px;
    text-align: center;
    /* float: left; */
    clear: left;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .mintCardsComingSoonButton {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #0057E1;
    font-family: var(--mont) !important;
    background-color: #E5EBFA;
    border: 0;
    width: 100%;
    max-width: 100% !important;
    /* margin: 0 auto; */
    border-radius: 7px;
    text-align: center;
    /* float: left; */
    clear: left;
    display: block;
  }
}



.mintCardsComingSoonButton:hover {
  background-color: #0057E1;
  color: #fff;
}

.mintCardsComingSoonButtonHalfSeeEarnings {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  border-radius: 7px;
  text-align: center;
  width: 200px !important;
  /* float: left; */
  /* clear: none !important; */
  margin-right: 10px !important;

}

.customTab {
  background-color: #6c757d !important;
  border: 1px solid white !important;
  color: white;
}

.nav-tabs .active {
  background-color: white !important;
  color: black;
}


.mintCardsComingSoonButtonFullSeeEarnings {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  border-radius: 7px;
  text-align: center;
  width: 100% !important;
  /* margin-right: 10px !important; */
}



.mintCardsComingSoonButtonHalfSeeEarnings:hover {
  background-color: #0057E1;
  color: #fff;
}

.mintCardsComingSoonButtonHalfClaimPayout {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #E5EBFA;
  border: 0;
  border-radius: 7px;
  text-align: center;
  width: 200px !important;
  float: none !important;
  clear: none !important;

}

.mintCardsComingSoonButtonHalfClaimPayout:hover {
  background-color: #0057E1;
  color: #fff;
}

.mintCardsOrange {
  font-size: 20px !important;
  font-weight: bolder;
  color: #fff;
  background-color: #E66F00;
  border: 0;
  width: 100%;
  max-width: 410px !important;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
}

.mintCardsOrange:hover {
  background-color: #0057E1;
}

.mainResultDescription {
  text-align: left !important;
}
.mainResultDescription .container{
  padding-left: 0px;
}

@media only screen and (max-width: 995px) {
  .mintCardsCardMain {
    margin-right: 0px;
    max-height: 250px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 768px) {
  .mintCardsPageHeading {
    font-size: 35px;
  }

  .mintCardsCardMain {
    margin-right: 0px;
    max-height: 250px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }

  .mintCardsLinksHeadnig {
    font-size: 30px;
  }

  .mainResultDescription {
    text-align: center !important;
  }
}
@media only screen and (max-width: 767px) {
  .iframeCenter {
    display: flex;
    justify-content: center;
  }
  .iframeSubheading {
    margin-left: 0px !important;
    text-align: center !important;
  }
}

.signupText {
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 440px) {}

/* mintCards Page  End*/
