
.matchesContainer {
  color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 4rem;
}

.matchesTitle {
  font-size: 3rem;
}

.matchesTable {
  margin-top: 4rem;
}

.table thead th {
  color: white !important;
  background-color: transparent !important;
  border: 2px solid white;
  text-align: center;
  font-size: 1.25rem;
  padding: 1.25rem;
}

.table tbody tr {
  color: white;
  background-color: transparent;
  text-align: center;
}

.table tbody tr td {
  border: 2px solid white;
  padding: 1.25rem;
}

@media only screen and (max-width: 991px) {
  .table {
    color: white !important;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 992px) {
  .table {
    color: white !important;
    overflow-x: auto;
    white-space: nowrap;
  }
}
