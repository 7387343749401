.earningsBody {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  overflow: visible;
  padding: 30px;
}

.earningMainCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin: 20px;
  /* height: 40px; */
}

.earningMainCard1 {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  height: 400px;
  position: relative;
}

.earningMainCard1Items {
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.earningImageCol {
  text-align: right;
}

.earningTitle {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #6b6b6b;
}

.earningAmount {
  font-size: 10vh;
  font-weight: 600;
  letter-spacing: 3px;
  color: #00d40b;
}

.earningSubAmount {
  font-size: 0.25em;
  position: absolute;
  bottom: -6px;
  right: 0px;
  color: #a0a0a0 !important;
}

.earningAmountSecondary {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #6b6b6b;
  margin-left: 10px;
}

.shadowGreen {
  background-color: rgba(38, 190, 0, 0.8);
  height: 3px;
  margin: 0 10px;
}

.shadowRed {
  background-color: rgba(202, 0, 0, 0.8);
  height: 3.5px;
  margin: 0 10px;
}

.shadowBlue {
  background-color: rgba(0, 0, 204, 0.8);
  height: 3px;
  margin: 0 10px;
}

.earningSmallHeading {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #000;
  margin-left: 10px;
}

.earningSmallSubHeading {
  color: #a0a0a0;
}

.queryIcon {
  font-size: 7px;
  color: #3b3b3b;
  background-color: #c7c6c6;
  padding: 4px;
  border-radius: 100%;
  cursor: pointer;
}

.falseQueryIconDescription {
  display: none;
}

.trueQueryIconDescription {
  position: absolute;
  top: -80px;
  left: 136px;
  height: 80px;
  width: 256px;
  z-index: 1;
  color: rgb(163, 163, 163) !important;
  background-color: #f0f0f0;
  border-radius: 10px;
  font-size: 0.7em;
  padding: 10px;
  display: block;
}

.falseLevel2IconDescription {
  display: none;
}

.trueLevel2IconDescription {
  position: absolute;
  top: -80px;
  left: -16px;
  width: 180px;
  height: 50px;
  z-index: 1;
  background-color: whitesmoke;
  color: #a0a0a0;
  border-radius: 10px;
  font-size: 0.7em;
  padding: 10px;
  display: block;
}


.falseNetPriceIconDescription {
  display: none;
}

.trueNetPriceIconDescription {
  position: absolute;
  top: -45px;
  left: 174px;
  width: 287px;
  height: 68px;
  z-index: 2;
  background-color: whitesmoke;
  color: #a0a0a0;
  border-radius: 10px;
  font-size: 0.6em !important;
  padding: 10px;
  display: block;
}


.queryDescriptionCloseIcon {
  position: absolute;
  top: 2px;
  right: 8px;
  cursor: pointer;
  color: black !important;
}

.earningImage {
  height: 170px;
  width: 120px;
}

.imageValues {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #6b6b6b;
  margin-bottom: 7px;
}

.imageValues span {
  font-size: 18px;
  color: #5a5a5a;
}

.myNFTSecondHeadingEarning {
  color: #000;
  font-family: var(--mont);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.05em;
}

.lifeTimeEarningsWhiteBackgroundEarning {
  border-radius: 10px;
  background: #ebebeb;
  padding: 0 !important;
  width: 100%;
  /* max-width: 440px !important; */
  margin: 0 auto;
  justify-content: center;
  height: auto;
  clear: left !important;
  display: block;
}

.myNFTHeadingAddressLabelEarning {
  color: #000;
  font-family: var(--mont);
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.02em;
  width: 83%;
}

.myNFTSeeDetailsEarningButtonEarning {
  font-size: 15px !important;
  font-weight: bold !important;
  color: #535353;
  font-family: var(--mont) !important;
  background-color: #adadad;
  border: 0;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;
  padding: 5px 5px !important;
  width: 45px;
}

.myNFTSeeDetailsEarningButtonEarning:hover {
  background-color: #0057E1;
  color: #fff;
}

.socialMediaIcon {
  height: 45px;
  width: 44px;
}

.myNFTSecondSmallHeadingEarning {
  color: #535353;
  font-family: var(--mont);
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.05em;
}

.btnRarityCardsOpensea {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #0057E1;
  font-family: var(--mont) !important;
  background-color: #fff;
  border: 1px solid #0057E1;
  width: 100%;
  max-width: 440px !important;
  margin: 0 auto;
  border-radius: 7px;
  text-align: center;

}

.btnRarityCardsOpensea:hover {
  background-color: #0057E1;
  color: #fff;
}

.selectLifeTimeEarning1 {
  cursor: pointer;
  float: right;
  display: inline;
}

.selectLifeTimeEarning1Column1 {
  padding-right: 0px !important;
}

.selectLifeTimeEarning1ValueHeading {
  color: #000;
  font-family: var(--mont);
  font-weight: bold;
  font-size: 16.2px;
}

.selectLifeTimeEarning1DropdownImage {
  display: block;
}

/* Dropdown1 */
.selectCryptoDropdown1Earning {
  border: 1px solid #535353;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 215px !important;
  background-color: #fff;
  height: 46px;
  margin-top: -16px;
  right: 60px;
  z-index: 1000;
  color: #a0a0a0;
}

.selectCryptoDropdown2Earning {
  border: 1px solid #535353;
  cursor: pointer;
  position: absolute;
  width: 215px !important;
  background-color: #fff;
  height: 46px;
  margin-top: 29px;
  right: 60px;
  z-index: 1000;
  color: #a0a0a0;
}

.selectCryptoDropdown3Earning {
  border: 1px solid #535353;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 215px !important;
  background-color: #fff;
  height: 46px;
  margin-top: 74px;
  right: 60px;
  z-index: 1000;
  color: #a0a0a0;
}

.selectCryptoDropdownEarning:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.selectCryptoColumn1Earning {
  padding-right: 0px !important;
}

.selectCryptoValueHeadingEarning {
  font-family: var(--mont);
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
}

/* Dropdown2 */
.selectCryptoDropdown1Earning2 {
  border: 1px solid #535353;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 215px !important;
  background-color: #fff;
  height: 46px;
  margin-top: 126px;
  right: 40px;
  z-index: 1000;
  color: #a0a0a0;
}

.selectCryptoDropdown2Earning2 {
  border: 1px solid #535353;
  cursor: pointer;
  position: absolute;
  width: 215px !important;
  background-color: #fff;
  height: 46px;
  margin-top: 171px;
  right: 40px;
  z-index: 1000;
  color: #a0a0a0;
}

.selectCryptoDropdown3Earning2 {
  border: 1px solid #535353;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 215px !important;
  background-color: #fff;
  height: 46px;
  margin-top: 216px;
  right: 40px;
  z-index: 1000;
  color: #a0a0a0;
}

.selectCryptoDropdownEarning2:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.selectCryptoColumn1Earning2 {
  padding-right: 0px !important;
}

.selectCryptoValueHeadingEarning2 {
  font-family: var(--mont);
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
}

.earningByLevelHeading {
  color: #000;
  font-family: var(--mont);
  font-weight: 600;
  font-size: 36px;
  letter-spacing: 0.05em;
  margin-top: 10px !important;
}

.earningTempCard {
  border-radius: 10px;
  margin: 20px;
}

.smallCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px 18px;
}

.smallCardRed {
  border: 1px solid rgb(202, 0, 0);
}

.smallCardH1 {
  color: #000;
  font-family: var(--mont);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.smallCardH2 {
  color: #00d40b;
  font-family: var(--mont);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.smallCardH2Red {
  color: rgb(202, 0, 0);
}

.smallCardH3 {
  color: #00d40b;
  font-family: var(--mont);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.smallCardH3Gray {
  color: #a0a0a0;
}

.smallCardH4 {
  color: #535353;
  font-family: var(--mont);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 0 3px;
}

.smallCardH5 {
  color: #535353;
  font-family: var(--mont);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 0 3px;
}

.smallCardSubText {
  font-size: 0.6em !important;
  color: #a0a0a0;
}

.transactionsCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 18px;
  margin: 10px 0px;
}

.transactionsTitle {
  color: #000;
  font-family: var(--mont);
  font-size: 33px;
  font-weight: 600;
  letter-spacing: 0.07em;
}

.transactionTitleLineDiv {
  width: 100%;
  align-items: center !important;
  justify-content: center !important;
}

.transactionTitleLine {
  text-align: center !important;
  align-self: center !important;
  margin: 0 44%;
  height: 0.5px;
  /* width: 50px; */
  background-color: #535353;
}

.tableTransactionDiv {
  overflow-x: auto;
  width: 100%;
  margin: 0px 5%;
}

.tableTransaction {
  width: 90%;
}

.tableHeader {
  padding-bottom: 5px;
  border-bottom: 0.4px solid #000;
}

.tableHeading {
  padding-bottom: 15px;
  color: #000;
  font-size: 17px !important;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1.8px;
}

.tableData {
  padding: 15px 5px;
  color: #858585;
  border-left: 0.4px solid rgba(105, 105, 105, 0.6);
  border-bottom: 0.4px solid rgba(105, 105, 105, 0.6);
  font-size: 17px;
  letter-spacing: 1.8px;
  font-weight: 900;
  text-align: center;
}
.tableDataRed{
  color: red !important;
}
.tableDataRemoveLeftBorder {
  border-left: 0px;
}

.tableDataRemoveRightBorder {
  border-right: 0px;
}

.TransactionCardPaginationBtn {
  text-align: center;
  background-color: rgb(245, 245, 245);
  padding: 9px 30px 9px 30px;
  border-radius: 10px;
  color: #646464;
  font-weight: 600;
  position: relative;
  top: 25px;
  cursor: pointer;
}
.TransactionCardPaginationBtn:hover {
  background-color: #0057E1;
  color: #fff;
}

.selectCryptoItems2 {
  position: relative;
  top: -65px;
  left: 70px;
}

.selectCryptoItems1 {
  position: relative;
  top: -15px;
  right: -50px;
}


@media only screen and (max-width: 995px) {
  .myNFTHeadingAddressLabelEarning {
    width: min-content !important;
  }

  .earningImageCol {
    text-align: center !important;
  }
}

@media only screen and (max-width: 694px) {
  .selectLifeTimeEarning1 {
    float: none;
  }

  .earningAmount {
    font-size: 5vh;
  }
}


@media only screen and (max-width: 380px) {}

@media only screen and (max-width: 575px) {
  .earningMainCard1 {
    height: 565px;
  }

  .trueQueryIconDescription {
    left: -14px;
  }

  /* .earningMainCard {
    height: 715px;
  } */

  .trueNetPriceIconDescription {
    top: -45px;
    left: 3px;
    width: 241px;
  }
}

@media (min-width: 576px) {
  .earningMainCard1 {
    height: 400px;
  }

  .earningAmountSecondary {
    font-size: 11px;
  }

  .trueQueryIconDescription {
    left: 131px;
    height: 70px;
    top: -67px;
  }

  .selectCryptoItems1 {
    top: 7px;
    left: 56px;
  }

  .selectCryptoItems2 {
    top: -65px;
    left: 62px;
  }

  /* .earningMainCard {
    height: 615px;
  } */

  .trueLevel2IconDescription {
    top: -75px;
    left: -36px;
  }

  .trueNetPriceIconDescription {
    top: -38px;
    left: -224px;
  }

  .tableHeading {
    font-size: 9px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  .earningAmountSecondary {
    font-size: 20px;
  }

  .trueQueryIconDescription {
    left: -105px;
    font-size: 0.5em;
  }

  /* .earningMainCard {
    height: 618px;
  } */

  .smallCardH2 {
    font-size: 8px;
  }

  .handleSmallCard {
    max-width: 14% !important;
    padding: 0 7px !important;
  }

  .smallCard {
    padding: 5px 1px !important;
  }

  .selectCryptoItems1 {
    top: 1px;
    left: 58px;
  }

  .selectCryptoItems2 {
    top: -64px;
    left: 58px;
  }

  .trueLevel2IconDescription {
    font-size: 0.9em;
  }

  .handleSmallCardWrap {
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .selectCryptoItems1 {
    top: 7px;
  }

  .earningAmountSecondary {
    font-size: 17px;
  }

  .earningSmallHeading {
    font-size: 13px;
  }

  .trueQueryIconDescription {
    left: 112px;
  }

  /* .earningMainCard {
    height: 585px;
  } */

  .smallCardH2 {
    font-size: 11px;
  }

  .trueLevel2IconDescription {
    top: -75px;
    left: -48px;
  }

  .trueNetPriceIconDescription {
    top: -47px;
    left: 133px;
  }

  .handleSmallCardWrap {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  .selectCryptoItems1 {
    top: -25px;
    left: 65px;
  }

  .selectCryptoItems2 {
    top: -70px;
    left: 96px;
  }

  .earningAmountSecondary {
    font-size: 22px;
  }

  /* .earningMainCard {
    height: 500px;
  } */

  .trueLevel2IconDescription {
    top: -75px;
    left: -3px;
  }

  .trueQueryIconDescription {
    font-size: 0.7em;
  }

  .selectLifeTimeEarning1 {
    position: relative !important;
    top: 7% !important;
  }

  .handleSmallCardWrap {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
  }
}


/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

  .selectCryptoItems1 {
    top: -40px;
  }

  .handleSmallCardWrap {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
  }
}