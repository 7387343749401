  /* login page */
  .loginMainHead {
    font-size: 62px;
    color: #fff;
    font-family: var(--mont);
    font-weight: 500;
    margin-top: 50px;
    letter-spacing: 7px;
  }
  .btnSignup {
    /* background-color: rgb(255, 64, 64) !important; */
    background-color: rgb(0, 87, 225) !important;
    color: #fff !important;
    font-family: var(--mont) !important;
    font-weight: bold !important;
    font-size: 22px !important;
    width: 100%;
    max-width: 410px !important;
    margin: 0 auto;
  }
  .btnSignup:hover {
    background-color: rgb(200, 224, 254) !important;
  }
  
  .loginTermWrapper {
    max-width: 410px !important;
    margin: 0 auto;
  }
  .loginInput {
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: #fff !important;
    border: none !important;
    font-size: 22px !important;
    font-weight: bold !important;
    font-family: var(--mont);
    border-radius: 5px;
  }
  .loginInput:focus {
    box-shadow: none !important;
  }
  .confirmationFailedSpan{
    color: red;
  }
  
  @media only screen and (max-width: 768px) {
    .loginMainHead {
      font-size: 35px;
    }
    .btnSignup {
      font-weight: bold !important;
      font-size: 18px !important;
      padding: 10px 50px !important;
    }
  }
  /* login page end */
  /*****************/
  