.mainBoxWrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.mainBox {
  /* max-width: 980px !important;
  width: 980px !important; */
  /* height: 90vh !important; */

  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 30px;
  /* margin: auto; */
  padding: 0px 0px 50px 0px !important;
  position: relative !important;
}
/* copied */
.image-wrapper {
  text-align: center;
  margin-top: 80px;
}
.image-card-1 {
  margin-right: 15px;
  max-height: 250px;
  object-fit: contain;
}
.image-card-2 {
  margin-left: 15px;
  max-height: 250px;
  object-fit: contain;
}
.content-wrapper {
  text-align: center;
}
.main-heading {
  color: #fff;
  font-family: var(--mont);
  font-size: 5rem;
  font-weight: 400;
  margin-top: 30px;
}
.main-description {
  color: #fff;
  font-family: var(--mont);
  font-weight: 300;
  font-size: 1.8rem;
  letter-spacing: 1px;
}
.main-para {
  color: #fff;
  font-family: var(--mont);
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 30px;
}
.main-enter {
  display: inline-block;
  color: #fff;
  font-size: 1.4rem;
  margin-top: 65px;
  margin-left: 20px;
}
.main-enter span {
  font-weight: bold;
}
.main-enter img {
  width: 20px;
  object-fit: contain;
  margin-top: -10px;
}
.btn-main {
  background-color: #fff !important;
  color: #000 !important;
  padding: 10px 20px !important;
  display: inline-block;
  font-family: var(--mont) !important;
  font-size: 2rem !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  margin-top: 50px;
}

.btn-results {
  background-color: #fff !important;
  color: #000 !important;
  padding: 10px !important;
  display: inline-block;
  font-family: var(--mont) !important;
  font-size: 1.3rem !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  margin-top: 10px;
}
.button-wrapper {
  display: flex;
  justify-content: center;
}
.headerCodeSnippets{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 100px;
  background-color: #000;
}
.footerCodeSnippets{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  height: 100px;
  background-color: #000;
}

@media only screen and (max-width: 1370px) {
  .image-wrapper {
    margin-top: 50px;
  }
  .main-heading {
    font-size: 3rem;
    font-weight: 400;
    margin-top: 20px;
  }
  .main-description {
    font-weight: 300;
    font-size: 1.3rem;
    letter-spacing: 1px;
  }
  .btn-main {
    padding: 8px 15px !important;
    font-size: 1.5rem !important;
    font-weight: bold !important;
    border-radius: 15px !important;
    margin-top: 20px;
  }
  .main-enter {
    display: inline-block;
    font-size: 1.4rem;
    margin-top: 25px;
    margin-left: 20px;
  }
  .image-card-1 {
    margin-right: 15px;
    max-height: 200px;
    object-fit: contain;
  }
  .image-card-2 {
    margin-left: 15px;
    max-height: 200px;
    object-fit: contain;
  }
  .mainBox {
    padding: 0px 0px 20px 0px !important;
  }
}
@media only screen and (max-width: 992px) {
  .main-heading {
    font-size: 3.5rem;
    font-weight: 400;
    margin-top: 15px;
  }
  .main-description {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0px;
  }
  .btn-main {
    padding: 10px 20px !important;
    /* display: block !important; */
    font-size: 1.5rem !important;
    font-weight: 600 !important;
  }
}
@media only screen and (max-width: 786px) {
  .image-card-1 {
    width: 120px;
    object-fit: contain;
  }
  .image-card-2 {
    width: 120px;
    object-fit: contain;
  }
  .main-heading {
    font-size: 2.5rem;
    font-weight: 400;
    margin-top: 15px;
  }
  .main-description {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .button-wrapper {
    display: block;
  }
  .btn-main {
    padding: 10px 20px !important;
    /* display: block !important; */
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    margin-top: 10px;
  }
  .main-enter {
    margin-top: 20px;
    display: none;
  }
  .mainBox {
    padding: 0px 0px 20px 0px !important;
  }
}
/* copied end */
